import React from 'react';
import {App, Button, Form, Modal, Space} from 'antd';
import axios from 'axios';
import {Store} from 'antd/es/form/interface';

export interface UpdateModalProps<T> {
    item: T
    title: string

    errorText?: string,
    targetUrl: string,

    method?: 'PUT' | 'POST',

    onClose?: () => void
    children: React.ReactNode
}

export const UpdateModal = <T extends Store, >(props: UpdateModalProps<T>) => {
    const {notification} = App.useApp();

    const displayError = (msg: string, e?: any) => {
        console.log(e);
        notification.error({
            message: msg,
        });
    };

    const validateMessages = {
        required: '${label} wird benötigt!',
    };

    const onFinish = (values: any) => {
        let call;
        if (!props.method || props.method == 'PUT') {
            call = axios.put(props.targetUrl, values);
        } else if (props.method == 'POST') {
            call = axios.post(props.targetUrl, values);
        } else {
            throw new Error('Unknown Request Type');
        }
        call.then(props.onClose)
            .catch((e) => displayError(props.errorText || 'Fehler beim Ausführen der Aktion', e));
    };

    return <Modal
        open={true}
        onCancel={props.onClose}
        footer={null}
    >
        <h3>{props.title}</h3>
        <Form
            onFinish={onFinish}
            layout="vertical"
            validateMessages={validateMessages}
            initialValues={{
                ...props.item,
            }}
        >
            {props.children}

            <Space style={{width: '100%', justifyContent: 'flex-end'}}>
                <Button onClick={props.onClose}>
                    Abbrechen
                </Button>

                <Button type="primary" htmlType="submit">
                    Fertig
                </Button>
            </Space>
        </Form>
    </Modal>;
};