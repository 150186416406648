import {Variant} from './Product';
import {User} from './User';
import {Company} from './Company';

export interface OrderBundle {
    id: string
    bundleNumber: number
    userId: string
    user: User
    companyId: string
    company: Company
    pickingNumber: string
    date: string
    deliveryAddress: Address
    status: OrderBundleStatus
    examinationHistory: ExaminationHistory[]
    comment: string
    priceNet: number
    priceGross: number
    orders: Order[]
}

export type ExaminationHistory = {
    userId: string
    date: string
    status: string
}

export enum ExaminationStatus {
    REQUESTED = 'REQUESTED',
    REFUSED = 'REFUSED',
    PERMITTED = 'PERMITTED',
    PERMITTED_WITH_EDITS = 'PERMITTED_WITH_EDITS',
    PERMIT_PENDING = 'PERMIT_PENDING',
    PERMIT_PENDING_WITH_EDITS = 'PERMIT_PENDING_WITH_EDITS',
    FORWARDED = 'FORWARDED',
}

export enum OrderBundleStatus {
    IN_PROCESS = 'IN_PROCESS',
    IS_PAYED = 'IS_PAYED',
    FINISHED = 'FINISHED'
}

export type Order = {
    id: string
    sellerCompany: Company,
    buyerCompany: Company,
    bundleId: string
    orderNumber: string
    vendorName: string
    priceGross: number
    priceNet: number
    positions: OrderPosition[]
    delivery: Delivery
    orderBundle: OrderBundle
}

export type OrderPosition = {
    item: Variant
    amount: number
}

export type Delivery = {
    trackingLink: string
    identificationCode: string
    status: DeliveryStatus
    departureDate: string | null
    scheduledDate: string | null
}

export enum DeliveryStatus {
    IN_PROCESS = 'IN_PROCESS',
    SENT = 'SENT',
    DELIVERED = 'DELIVERED'
}

export type Address = {
    title: string;
    country: string;
    city: string;
    zip: string;
    street: string;
    streetNumber: string;
};