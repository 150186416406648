import {App, Dropdown, Space, Table} from 'antd';
import React, {useRef} from 'react';
import {Searchbar} from '../search/Searchbar';
import {AutoFetchData} from '../loading/AutoFetchData';
import {SorterResult} from 'antd/es/table/interface';
import Column from 'antd/es/table/Column';
import {DownOutlined, EditOutlined} from '@ant-design/icons';
import {AnyRecord} from 'dns';

interface AutoFetchTableProps<T, O> {
    transformFunction?: (u: T) => O,
    children: React.ReactNode,

    invalidateCallback?: React.MutableRefObject<(() => void) | undefined>,

    searchItems?: {
        value: string,
        label: string
        select?: { value: string, label: string }[],
        selectFetch?: (search: string) => Promise<{ value: string, label: string }[]>,
    }[],

    url: string,
    query?: {
        [key: string]: any
    }

    modal?: (item: T, onClose: () => void) => React.ReactNode,

    actionIcon?: React.ComponentType

    extraActions?: { label: string, callback: (item: T) => void, condition: (item: T) => boolean }[],
}

export const AutoFetchTable = <T, O extends T = T>(props: AutoFetchTableProps<T, O>) => {
    const {notification, modal} = App.useApp();
    const [items, setItems] = React.useState<O[]>([]);
    const [query, setQuery] = React.useState<{
        [v: string]: string
    }>({});
    const [editItem, setEditItem] = React.useState<T | null>(null);
    const [sortPrimary, setSortPrimary] = React.useState<string>('');
    const [sortDirection, setSortDirection] = React.useState<string>('');

    const innerInvalidateCallback = useRef<() => void>();

    if (props.invalidateCallback) {
        props.invalidateCallback.current = () => {
            if (innerInvalidateCallback.current) innerInvalidateCallback.current();
        };
    }

    const displayError = (msg: string, e?: any) => {
        console.error(e);

        notification.error({
            message: msg,
        });
    };

    const extraMenuItems = (item: T) => {
        const items = (props.extraActions || []).filter((action) => {
            if (action.condition) {
                return action.condition(item);
            } else {
                return true;
            }
        }).map((action) => (
            {
                key: action.label,
                label: <a onClick={() => action.callback(item)}>{action.label}</a>,
            }
        ));

        return {items};
    };

    const Icon = props.actionIcon ?? EditOutlined;

    return <Space direction="vertical" size="large" style={{width: '100%'}}>
        <div>
            <Searchbar items={props.searchItems || []}
                       onChange={setQuery}
            />

            {props.modal && editItem ? props.modal(editItem, () => {
                setEditItem(null);
                const callback = innerInvalidateCallback.current;
                if (callback) {
                    callback();
                }
            }) : <></>}
        </div>

        <AutoFetchData
            url={props.url}
            query={{
                sortPrimary: sortPrimary || '_id',
                sortDirectionPrimary: sortDirection || 'ASC',
                ...query,
                ...(props.query || {})
            }}
            invalidateCallback={innerInvalidateCallback}
            onErrorLoading={(e) => {
                displayError('Fehler beim Laden der Daten.', e);
            }}
            onLoadData={({data}: {
                data: T[]
            }) => {
                setItems(props.transformFunction ?
                    data.map(props.transformFunction) : (data as O[]));
            }}>
            <Table
                dataSource={items as AnyRecord[]}
                pagination={false}
                rowKey="id"
                onChange={(pagination, filters, sorter: SorterResult<AnyRecord> | SorterResult<AnyRecord>[]) => {
                    if (!Array.isArray(sorter)) {
                        console.log(sorter);
                        if (sorter.order == 'ascend') {
                            setSortDirection('ASC');
                            setSortPrimary(sorter.columnKey + '');
                        } else if (sorter.order == 'descend') {
                            setSortDirection('DESC');
                            setSortPrimary(sorter.columnKey + '');
                        } else {
                            setSortDirection('');
                            setSortPrimary('');
                        }
                    }
                }}
                onRow={(record, rowIndex) => {
                    return {
                        onDoubleClick: (event) => {
                            setEditItem(record as T);
                        },
                    };
                }}
            >
                {props.children}
                <Column title="" dataIndex="id" key="actions"
                        render={(_: any, item: T) => (
                            <>
                                <Space>
                                    <Icon onClick={() => {
                                        setEditItem(item);
                                    }}/>
                                    {
                                        props.extraActions ?
                                            <Dropdown menu={extraMenuItems(item)} placement="bottomRight">
                                                <DownOutlined/>
                                            </Dropdown> : <></>
                                    }
                                </Space>
                            </>
                        )}
                />
            </Table>
        </AutoFetchData>
    </Space>;
};