import {ExaminationHistory, Order, OrderBundle} from '../../../interfaces/OrderBundle';
import {Col, Descriptions, Modal, Row, Space, Timeline} from 'antd';
import React from 'react';
import {RenderHelper} from '../../../util/renderHelper';
import {fetchAll} from '../../../util/fetchPaged';
import {ENDPOINTS} from '../../../constants/ApiEndpoints';
import {User} from '../../../interfaces/User';
import {DeliveryStatusNames, ExaminationHistoryNames} from '../../../util/enumNames';
import {CountiesISO3166_1} from '../../../data/country';
import {PositionsDetails} from './PositionsDetails';

export interface DetailOrderProps {
    order: OrderBundle,
    onClose?: () => void
}

export const DetailOrderBundle = (props: DetailOrderProps) => {
    const order = props.order;
    const [users, setUsers] = React.useState<User[]>([]);

    React.useEffect(() => {
        fetchAll<User>({
            url: ENDPOINTS.adminListUsers, query: {
                companyId: order.companyId
            }
        }).then(r => setUsers(r.fetched));
    }, []);

    const countryName = (code: string) => {
        return CountiesISO3166_1.find(v => v.value == code)?.label || code;
    };

    const historyToTimelineItem = (history: ExaminationHistory) => {
        const user = users.find(u => u.id == history.userId);
        return {
            children: <>
                <b>{ExaminationHistoryNames[history.status] || history.status}.</b> <br/>
                {RenderHelper.dateTime(history.date)}, {user?.firstName ?? ''} {user?.lastName ?? ''}
            </>,
        };
    };

    const renderOrder = (order: Order) => {
        return <>
            <Descriptions bordered column={2}>
                <Descriptions.Item label="Verkäufer" span={2}>
                    {order.sellerCompany?.name}
                </Descriptions.Item>

                <Descriptions.Item label="Versandstatus" span={1}>
                    {DeliveryStatusNames[order.delivery.status] || order.delivery.status}
                </Descriptions.Item>

                <Descriptions.Item label="Geschätztes Versanddatum" span={1}>
                    {order.delivery.scheduledDate ? RenderHelper.dateTime(order.delivery.scheduledDate) : '-'}
                </Descriptions.Item>

                <Descriptions.Item label="Sendungsnummer" span={1}>
                    {order.delivery.identificationCode || '-'}
                    {order.delivery.trackingLink && order.delivery.trackingLink.startsWith('http') ?
                        <a href={order.delivery.trackingLink}><br/>{order.delivery.trackingLink}</a> : []}
                </Descriptions.Item>

                <Descriptions.Item label="Versanddatum" span={1}>
                    {order.delivery.departureDate ? RenderHelper.dateTime(order.delivery.departureDate) : '-'}
                </Descriptions.Item>

                <Descriptions.Item label="Positionen" span={4}>
                    <PositionsDetails positions={order.positions}/>
                </Descriptions.Item>

                <Descriptions.Item label="Nettopreis" span={2}>
                    {RenderHelper.cents(order.priceNet)}
                </Descriptions.Item>

                <Descriptions.Item label="Bruttopreis" span={2}>
                    {RenderHelper.cents(order.priceGross)}
                </Descriptions.Item>

            </Descriptions>
        </>;
    };

    return <Modal
        open={true}
        onCancel={props.onClose}
        footer={null}
        width={520 * 3}
    >
        <Row gutter={[24, 16]}>
            <Col span={12}>
                <Space direction={'vertical'} size={'large'} style={{width: '100%'}}>
                    <h1>Bestellung</h1>
                    <Descriptions bordered column={2}>
                        <Descriptions.Item label="Nummer" span={1}>
                            #{order.bundleNumber}
                        </Descriptions.Item>
                        <Descriptions.Item label="Nettopreis" span={1}>
                            {RenderHelper.cents(order.priceNet)}
                        </Descriptions.Item>
                        <Descriptions.Item label="Datum" span={1}>
                            {RenderHelper.dateTime(order.date)}
                        </Descriptions.Item>

                        <Descriptions.Item label="Bruttopreis" span={1}>
                            {RenderHelper.cents(order.priceGross)}
                        </Descriptions.Item>

                        <Descriptions.Item label="Verlauf" span={2}>
                            <Timeline
                                style={{
                                    paddingTop: 32,
                                }}
                                items={order.examinationHistory.map(historyToTimelineItem)} reverse={true}
                            />
                        </Descriptions.Item>
                    </Descriptions>

                    <Descriptions title="Käufer" bordered column={2}>
                        <Descriptions.Item
                            span={1}
                            label="Besteller">{order.user.firstName} {order.user.lastName} <br/>
                            {order.user.mail}</Descriptions.Item>

                        <Descriptions.Item label="Unternehmen" span={1}>
                            {order.company.name}
                        </Descriptions.Item>

                        <Descriptions.Item span={2} label={'Kommissionierungsnummer'}>
                            {order.pickingNumber || '-'}
                        </Descriptions.Item>

                        <Descriptions.Item label="Addresse" span={2}>
                            {order.deliveryAddress.title} <br/>
                            {order.deliveryAddress.street} {order.deliveryAddress.streetNumber} <br/>
                            {order.deliveryAddress.zip} {order.deliveryAddress.city} <br/>
                            {countryName(order.deliveryAddress.country)}
                        </Descriptions.Item>
                    </Descriptions>

                </Space>
            </Col>
            <Col span={12}>
                <Space direction={'vertical'} size={'large'} style={{width: '100%'}}>
                    <h2 style={{
                        marginTop: 48,
                        marginBottom: 0,
                    }}>Inhalt</h2>
                    {order.orders.map(renderOrder)}
                </Space>
            </Col>
        </Row>

    </Modal>;
};