import {Flex, Modal, Segmented, Space, Typography} from 'antd';
import {Company} from '../../../interfaces/Company';
import React from 'react';
import axios from 'axios';
import {ENDPOINTS} from '../../../constants/ApiEndpoints';
import {CompanyStat} from '../../../interfaces/CompanyStat';
import {useErrorHandling} from '../../../components/error/ErrorHandlerProvider';
import {Picker, TimeSpanPicker} from './TimeSpanPicker';
import {AutoChart, CompanyStatField} from './AutoChart';
import {RenderHelper} from '../../../util/renderHelper';


export interface StatDetailProps {
    company: Company,
    initialDateRange: [[string, string], Picker],
    onClose?: () => void
}

export const DetailStat = (props: StatDetailProps) => {
    const handleError = useErrorHandling();
    const [initialTimeSpan, initialPick] = props.initialDateRange;

    const [data, setData] = React.useState<CompanyStat[]>([]);
    const [dataKind, setDataKind] = React.useState('soldNetVolume' as CompanyStatField);
    const [startDate, setStartDate] = React.useState(initialTimeSpan[0]);
    const [endDate, setEndDate] = React.useState(initialTimeSpan[1]);

    React.useEffect(() => {
        axios.get(ENDPOINTS.adminGetCompanyStats(props.company), {
            params: {
                from: startDate,
                until: endDate,
            }
        }).then(v => setData(v.data)).catch(handleError);
    }, [startDate, endDate]);

    const options = [
        {
            value: 'soldNetVolume',
            label: 'Umsatz',
        },
        {
            value: 'soldNumberOfItems',
            label: 'Artikel verkauft',
        },
        {
            value: 'soldNumberOfOrders',
            label: 'Bestellungen',
        },

    ];


    return <Modal
        open={true}
        onCancel={props.onClose}
        footer={null}
        width={2 * 540}
    >
        <Space direction={'vertical'} size={'large'} style={{width: '100%'}}>
            <Typography.Title level={3}>{props.company.name}</Typography.Title>
            <span>Verkäufer seit <b>{RenderHelper.date(props.company.created)}</b>.</span>

            <Flex justify={'space-between'}>
                <Segmented options={options} value={dataKind} onChange={c => setDataKind(c as CompanyStatField)}/>

                <TimeSpanPicker
                    defaultPicker={initialPick != 'date' ? initialPick : 'week'}
                    defaultDates={props.initialDateRange[0]}

                    onChange={([start, end]) => {
                        setStartDate(start);
                        setEndDate(end);
                }}
            />
            </Flex>
            <AutoChart data={data} fieldName={dataKind}/>
        </Space>

    </Modal>;
};