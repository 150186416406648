import {DeliveryStatus, ExaminationStatus, OrderBundleStatus} from '../interfaces/OrderBundle';

export const ExaminationHistoryNames: { [_: string]: string } = {};
ExaminationHistoryNames[ExaminationStatus.REQUESTED] = 'Beantragt';
ExaminationHistoryNames[ExaminationStatus.REFUSED] = 'Abgelehnt';
ExaminationHistoryNames[ExaminationStatus.PERMITTED] = 'Genehmigt';
ExaminationHistoryNames[ExaminationStatus.PERMITTED_WITH_EDITS] = 'Verändert und Genehmigt';
ExaminationHistoryNames[ExaminationStatus.PERMIT_PENDING] = 'Genehmigung austehend';
ExaminationHistoryNames[ExaminationStatus.PERMIT_PENDING_WITH_EDITS] = 'Genehmigung mit Änderungen austehend';
ExaminationHistoryNames[ExaminationStatus.FORWARDED] = 'Weitergeleitet';

export const DeliveryStatusNames: { [_: string]: string } = {};
DeliveryStatusNames[DeliveryStatus.IN_PROCESS] = 'In Bearbeitung';
DeliveryStatusNames[DeliveryStatus.SENT] = 'Versendet';
DeliveryStatusNames[DeliveryStatus.DELIVERED] = 'Zugestellt';


export const OrderBundleStatusNames: { [_: string]: string } = {};
OrderBundleStatusNames[OrderBundleStatus.IN_PROCESS] = 'In Bearbeitung';
OrderBundleStatusNames[OrderBundleStatus.IS_PAYED] = 'Bezahlt';
OrderBundleStatusNames[OrderBundleStatus.FINISHED] = 'Abgeschlossen';
