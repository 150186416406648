import React from 'react';
import './App.css';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import {Login} from './features/login/Login';
import {AuthProvider} from './auth/AuthProvider';
import {Dashboard} from './features/dashboard/Dashboard';
import {ProtectedRoutes} from './auth/ProtectedRoutes';
import {DashboardLayout} from './components/layout/DashboardLayout';
import {App as AntApp, ConfigProvider} from 'antd';
import {MailInviteSetPassword} from './features/registration/MailInviteSetPassword';
import {PasswordReset} from './features/registration/PasswordReset';
import {PasswordResetDone} from './features/registration/PasswordResetDone';
import {ManageCompanies} from './features/companies/ManageCompanies';
import {ManageUsers} from './features/users/ManageUsers';
import {UserPermissions} from './interfaces/Permission';
import {
    AreaChartOutlined,
    FilterOutlined,
    SettingOutlined,
    ShopOutlined,
    ShoppingOutlined,
    TeamOutlined,
    UnorderedListOutlined,
    UserOutlined
} from '@ant-design/icons';
import {ManageAdmins} from './features/admin-users/ManageAdmins';
import {ManageManufacturer} from './features/manufacturer/ManageManufacturer';
import {ErrorHandlerProvider} from './components/error/ErrorHandlerProvider';
import {PageNotFound} from './features/error/PageNotFound';
import {ManageOrderBundles} from './features/order/ManageOrderBundles';
import {ManageOrder} from './features/order/ManageOrder';
import {SidebarTree} from './components/sidebar/Sidebar';
import {ManageFilters} from './features/filters/ManageFilters';
import {ManageCategories} from './features/categories/ManageCategories';
import {CompanyStats} from './features/companyStats/CompanyStats';
import deDE from 'antd/locale/de_DE';
import dayjs from 'dayjs';
import QuarterOfYear from 'dayjs/plugin/quarterOfYear';
import DayJsLocal from 'dayjs/locale/de';

const _DayJsLocal = DayJsLocal;

dayjs.extend(QuarterOfYear);
dayjs.locale('de');


const SidebarCategory = {
    UsersAndCompanies: '',
    Products: 'Produkte',
    Categories: 'kategorien & Filter',
    Orders: 'Bestellungen',
    Admin: 'Administartion',
};

const sidebar: SidebarTree[] = [
    {
        kind: 'Header',
        label: 'Dashboard',
        children: [
            {
                kind: 'Item',
                path: '/dashboard',
                label: 'Dashboard',
                permission: UserPermissions.ADMIN_USE,
                icon: <AreaChartOutlined/>,
                element: <Dashboard/>,
            },
            {
                kind: 'Item',
                path: '/sales',
                label: 'Umsätze',
                permission: UserPermissions.ADMIN_USE,
                icon: <AreaChartOutlined/>,
                element: <CompanyStats/>,
            },
        ],
    },
    {
        kind: 'Header',
        label: 'Produkte',
        children: [
            {
                kind: 'Item',
                path: '/manufacturers',
                label: 'Hersteller',
                permission: UserPermissions.ADMIN_MANAGE_MANUFACTURERS,
                icon: <SettingOutlined/>,
                element: <ManageManufacturer/>,
            },
            {
                kind: 'Item',
                path: '/filters',
                label: 'Filter',
                permission: UserPermissions.ADMIN_MANAGE_CATEGORIES,
                icon: <FilterOutlined/>,
                element: <ManageFilters/>,
            },
            {
                kind: 'Item',
                path: '/categories',
                label: 'Kategorien',
                permission: UserPermissions.ADMIN_MANAGE_CATEGORIES,
                icon: <UnorderedListOutlined/>,
                element: <ManageCategories/>,
            },

        ]
    },
    {
        kind: 'Header',
        label: 'Kundendaten',
        children: [
            {
                kind: 'Item',
                category: SidebarCategory.UsersAndCompanies,
                path: '/users',
                label: 'Nutzer',
                permission: UserPermissions.ADMIN_MANAGE_USERS,
                icon: <UserOutlined/>,
                element: <ManageUsers/>
            },
            {
                kind: 'Item',
                path: '/companies',
                label: 'Unternehmen',
                permission: UserPermissions.ADMIN_MANAGE_COMPANIES,
                icon: <ShopOutlined/>,
                element: <ManageCompanies/>,
            },
        ]
    },
    {
        kind: 'Header',
        label: 'Bestellungen',
        children: [
            {
                kind: 'Item',
                path: '/ordersBundles',
                label: 'Bestellungen',
                permission: UserPermissions.ADMIN_MANAGE_ORDER,
                icon: <ShoppingOutlined/>,
                element: <ManageOrderBundles/>,
            },
            {
                kind: 'Item',
                path: '/orders',
                label: 'Verkäufe',
                permission: UserPermissions.ADMIN_MANAGE_ORDER,
                icon: <ShoppingOutlined/>,
                element: <ManageOrder/>,
            },
        ]
    },
    {
        kind: 'Header',
        label: 'Administartion',
        children: [
            {
                kind: 'Item',
                path: '/admin-users',
                label: 'Administratoren',
                permission: UserPermissions.ADMIN_MANAGE_ADMINS,
                icon: <TeamOutlined/>,
                element: <ManageAdmins/>,
            }
        ]
    }
];

function sidebarTreeToRoutes(tree: SidebarTree): { path: string, element: React.ReactNode }[] {
    switch (tree.kind) {
        case 'Item':
            return [{path: tree.path, element: tree.element}];
        case 'Header':
            return tree.children.flatMap(sidebarTreeToRoutes);
    }
}

const router = createBrowserRouter([
    {
        path: '/',
        element: <Login/>,
    },
    {
        path: '/invitation/web/finish/:linkHash',
        element: <MailInviteSetPassword/>,
    },
    {
        path: '/password/update/done',
        element: <PasswordResetDone/>,
    },
    {
        path: '/password/update/:linkHash',
        element: <PasswordReset/>,
    },
    {
        path: '/',
        element: <ProtectedRoutes/>,
        children: [
            {
                element: <DashboardLayout sidebar={sidebar}/>,
                path: '/',
                children: sidebar.flatMap(sidebarTreeToRoutes).concat([
                    {
                        path: '*',
                        element: <PageNotFound/>
                    }
                ])
            }
        ]
    },
]);


function App() {
    return (
        <ConfigProvider locale={deDE}>
            <AntApp className="root">
                <AuthProvider>
                    <ErrorHandlerProvider>
                        <RouterProvider router={router}></RouterProvider>
                    </ErrorHandlerProvider>
                </AuthProvider>
            </AntApp>
        </ConfigProvider>
    );
}

export default App;
