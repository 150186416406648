import {Space, Tag} from 'antd';
import {AutoFetchTable} from '../../components/table/AutoFetchTable';
import {EyeOutlined} from '@ant-design/icons';
import {ENDPOINTS} from '../../constants/ApiEndpoints';
import {DeliveryStatus, Order, OrderBundleStatus} from '../../interfaces/OrderBundle';
import {SelectApiSearchHelper} from '../../util/SelectApiSearchHelper';
import Column from 'antd/es/table/Column';
import React, {useRef} from 'react';
import {RenderHelper} from '../../util/renderHelper';
import {DeliveryStatusNames, OrderBundleStatusNames} from '../../util/enumNames';
import {Collapse} from '../../components/misc/Collapse';
import {DetailOrder} from './components/DetailOrder';

export const ManageOrder = () => {
    const invalidateCallback = useRef<() => void>();

    function renderItemList(o: Order) {
        return <Collapse items={o.positions.map(o => `${o.amount}x ${o.item.name}`)}/>;
    }

    return <Space direction="vertical" size="large" style={{width: '100%'}}>
        <h1>Verkäufe</h1>

        <div>
        </div>

        <AutoFetchTable
            actionIcon={EyeOutlined}
            invalidateCallback={invalidateCallback}
            url={ENDPOINTS.adminOrder}
            modal={(item, onClose) => <DetailOrder order={item} onClose={onClose}/>}
            transformFunction={(order: Order) => (order)}
            query={{}}
            searchItems={[
                {value: 'buyerCompanyId', label: 'Käufer', selectFetch: SelectApiSearchHelper.findCompany},
                {value: 'sellerCompanyId', label: 'Verkäufer', selectFetch: SelectApiSearchHelper.findCompany},
            ]}>
            <Column title="Bestellnummer" dataIndex={['orderBundle', 'bundleNumber']} key="orderBundle.bundleNumber"
                    sorter={true}/>
            <Column title="Verkäufer" dataIndex={['sellerCompany', 'name']} key="sellerCompany.name" sorter={true}/>
            <Column title="Käufer" dataIndex={['buyerCompany', 'name']} key="buyerCompany.name" sorter={true}/>
            <Column title="Bestellstatus" dataIndex={['orderBundle', 'status']} key="orderBundle.status" sorter={true}
                    render={(status: OrderBundleStatus) => {
                        return <Tag>{OrderBundleStatusNames[status] || status}</Tag>;
                    }}/>
            <Column title="Versandstatus" dataIndex={['delivery', 'status']} key="buyerCompany.name" sorter={true}
                    render={(status: DeliveryStatus) => {
                        return <Tag>{DeliveryStatusNames[status] || status}</Tag>;
                    }}/>
            <Column title="Details" dataIndex="" width="20%" key="details"
                    render={renderItemList}/>
            <Column title="Nettopreis (Bruttopreis)" dataIndex="priceNet" key="priceNet" sorter={true}
                    render={(_: any, order: Order) => {
                        return `${RenderHelper.cents(order.priceNet)} (${RenderHelper.cents(order.priceGross)})`;
                    }}/>

        </AutoFetchTable>
    </Space>;
};