import {PermissionGroup} from './Permission';
import {Company} from './Company';

export type User = {
    id: string
    mail: string
    firstName: string
    lastName: string
    department: string
    pickingNumber: string
    userState: string
    image: string
    permissionGroupId: string
    permissionGroup: PermissionGroup
    supervisorId: string
    supervisor: User
    budget: number
    budgetMonthly: number
    companyId: string,
    company?: Company,
    unlimitedBudget: boolean
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace UserState {
    export const Active = 'ACTIVE';
    export const Blocked = 'BLOCKED';
    export const Invited = 'INVITED';
}