import {Outlet} from 'react-router-dom';

import './dashborad-layout.css';
import {Sidebar, SidebarProps} from '../sidebar/Sidebar';

export type DashboardLayoutProps = SidebarProps
export const DashboardLayout = (props: DashboardLayoutProps) => {
    return (<div className="dashboard-outer">
        <header className="dashboard-header"></header>
        <nav className="dashboard-sidebar"><Sidebar sidebar={props.sidebar}></Sidebar></nav>
        <main className="dashboard-main">
            <Outlet/>
        </main>
        <footer className="dashboard-footer"></footer>
    </div>);
};