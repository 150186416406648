import {Order, OrderBundle} from '../../../interfaces/OrderBundle';
import {Descriptions, Modal, Space} from 'antd';
import {DeliveryStatusNames} from '../../../util/enumNames';
import {RenderHelper} from '../../../util/renderHelper';
import React from 'react';
import {PositionsDetails} from './PositionsDetails';
import {CountiesISO3166_1} from '../../../data/country';
import {EyeOutlined} from '@ant-design/icons';
import {ENDPOINTS} from '../../../constants/ApiEndpoints';
import {useErrorHandling} from '../../../components/error/ErrorHandlerProvider';
import axios from 'axios';
import {DetailOrderBundle} from './DetailOrderBundle';

export interface DetailOrderProps {
    order: Order,
    onClose?: () => void
}


export const DetailOrder = (props: DetailOrderProps) => {
    const handleError = useErrorHandling();
    const [fullOrderBundle, setFullOrderBundle] = React.useState<OrderBundle | null>(null);
    const order = props.order;

    const showOrderBundleModal = () => {
        axios.get(ENDPOINTS.adminGetOrderBundles(order.orderBundle))
            .then(r => setFullOrderBundle(r.data))
            .catch(handleError);
    };

    const countryName = (code: string) => {
        return CountiesISO3166_1.find(v => v.value == code)?.label || code;
    };


    return <Modal
        open={true}
        onCancel={props.onClose}
        footer={null}
        width={520 * 2}
    >
        {fullOrderBundle &&
            <DetailOrderBundle onClose={() => setFullOrderBundle(null)} order={fullOrderBundle}/>
        }
        <Space direction={'vertical'} size={'large'}>
            <h2>Verkauf</h2>
            <Descriptions bordered column={2}>
                <Descriptions.Item label="Verkäufer" span={2}>
                    {order.sellerCompany?.name}
                </Descriptions.Item>

                <Descriptions.Item label="Versandstatus" span={1}>
                    {DeliveryStatusNames[order.delivery.status] || order.delivery.status}
                </Descriptions.Item>

                <Descriptions.Item label="Geschätztes Versanddatum" span={1}>
                    {order.delivery.scheduledDate ? RenderHelper.dateTime(order.delivery.scheduledDate) : '-'}
                </Descriptions.Item>

                <Descriptions.Item label="Sendungsnummer" span={1}>
                    {order.delivery.identificationCode || '-'}
                    {order.delivery.trackingLink && order.delivery.trackingLink.startsWith('http') ?
                        <a href={order.delivery.trackingLink}><br/>{order.delivery.trackingLink}</a> : []}
                </Descriptions.Item>

                <Descriptions.Item label="Versanddatum" span={1}>
                    {order.delivery.departureDate ? RenderHelper.dateTime(order.delivery.departureDate) : '-'}
                </Descriptions.Item>

                <Descriptions.Item label="Bestellnummer" span={1}>
                    <a onClick={showOrderBundleModal}>#{order.orderBundle.bundleNumber} <EyeOutlined/> </a>
                </Descriptions.Item>
            </Descriptions>

            <Descriptions title="Käufer" bordered column={2}>
                <Descriptions.Item label="Unternehmen" span={2}>
                    {order.buyerCompany.name}
                </Descriptions.Item>

                <Descriptions.Item span={2} label={'Kommissionierungsnummer'}>
                    {order.orderBundle.pickingNumber || '-'}
                </Descriptions.Item>

                <Descriptions.Item label="Addresse" span={2}>
                    {order.orderBundle.deliveryAddress.title} <br/>
                    {order.orderBundle.deliveryAddress.street} {order.orderBundle.deliveryAddress.streetNumber} <br/>
                    {order.orderBundle.deliveryAddress.zip} {order.orderBundle.deliveryAddress.city} <br/>
                    {countryName(order.orderBundle.deliveryAddress.country)}
                </Descriptions.Item>
            </Descriptions>

            <Descriptions bordered column={2} title="Details">
                <Descriptions.Item label="Positionen" span={4}>
                    <PositionsDetails positions={order.positions}/>
                </Descriptions.Item>

                <Descriptions.Item label="Nettopreis" span={2}>
                    {RenderHelper.cents(order.priceNet)}
                </Descriptions.Item>

                <Descriptions.Item label="Bruttopreis" span={2}>
                    {RenderHelper.cents(order.priceGross)}
                </Descriptions.Item>

            </Descriptions>
        </Space>
    </Modal>;
};