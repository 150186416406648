import {Manufacturer, ManufacturerState} from '../../../interfaces/Manufacturer';
import {Form, Input, Select} from 'antd';
import React from 'react';
import {ImageUpload} from '../../../components/input/ImageUpload';
import {ENDPOINTS} from '../../../constants/ApiEndpoints';
import {UpdateModal} from '../../../components/input/UpdateModal';
import {fetchAll} from '../../../util/fetchPaged';

const TARGET_ASPECT_RATIO = 2;
const TARGET_ASPECT_RATIO_MAX_DELTA = 0.3;

export interface EditManufacturerProps {
    manufacturer?: Manufacturer,
    onClose?: () => void
}

export const EditManufacturer = (props: EditManufacturerProps) => {
    const [allManufacturers, setAllManufacturers] = React.useState<Manufacturer[]>([]);

    React.useEffect(() => {
        fetchAll<Manufacturer>({url: ENDPOINTS.adminListManufacturer})
            .then((e) => setAllManufacturers(e.fetched));
    }, []);

    const nameValidator = (_: any, value: string) => {
        if (allManufacturers.find((i) =>
            // If name matches, but id do not
            i.name.toLowerCase().replace(/\s/g, '') == value.toLowerCase().replace(/\s/g, '') && i.id != props.manufacturer?.id
        )) {
            return Promise.reject(new Error('Dupplicate Name.'));
        }
        return Promise.resolve();
    };

    const imageValidator = (_: any, value: string) => {
        if (!value) return Promise.resolve();

        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = 'data:application/octet-stream;base64,' + value; // create data url.
            img.onload = () => {
                const aspect = img.width / img.height;
                if (Math.abs(aspect - TARGET_ASPECT_RATIO) > TARGET_ASPECT_RATIO_MAX_DELTA) {
                    reject(new Error('Aspect Ratio does not match'));
                } else {
                    resolve(null);
                }
            };
            img.onerror = (e) => {
                console.log(e);
                reject(new Error('Error loading image'));
            };
        });
    };


    const [method, target]: ['PUT' | 'POST', string] = props.manufacturer ?
        ['PUT', ENDPOINTS.adminUpdateManufacturer(props.manufacturer)] :
        ['POST', ENDPOINTS.adminCreateManufacturer];

    return <UpdateModal item={props.manufacturer || {}}
                        onClose={props.onClose}
                        title={'Hersteller'}
                        method={method}
                        targetUrl={target}>
        <Form.Item
            name="name"
            label="Name"
            rules={[
                {required: true},
                {
                    message: 'Der Name existiert bereits.',
                    validator: nameValidator
                }
            ]}
        >
                <Input/>
            </Form.Item>

            <Form.Item
                name="ImageData"
                label="Herstellerlogo"
                rules={[
                    {
                        message: 'Das Bild sollte im Querformat 2:1 sein.',
                        validator: imageValidator,
                        warningOnly: true,
                    }
                ]}
            >
                <ImageUpload initialImageId={props.manufacturer?.imageId}/>
            </Form.Item>

            <Form.Item name="manufacturerState" label="Status">
                <Select
                    options={[
                        {value: ManufacturerState.ManufacturerActive, label: 'Aktiv'},
                        {value: ManufacturerState.ManufacturerUnchecked, label: 'Nicht überprüft'},
                        {value: ManufacturerState.ManufacturerHidden, label: 'Ausgeblendet'},
                    ]}
                ></Select>
            </Form.Item>
    </UpdateModal>;
};