import {useNavigate, useParams} from 'react-router-dom';
import axios from 'axios';
import {ENDPOINTS} from '../../constants/ApiEndpoints';
import {CenterModal} from '../../components/layout/CenterModal';
import {Button, Space} from 'antd';
import {PasswordForm} from './component/PasswordForm';
import React, {useState} from 'react';

export const PasswordReset = () => {
    const {linkHash} = useParams<string>();
    const [expired, setExpired] = useState(false);

    const navigate = useNavigate();

    React.useEffect(() => {
        axios.get(ENDPOINTS.passwordResetInfo(linkHash || ''))
            .then(function (_) {
                setExpired(false);
            })
            .catch(_ => {
                setExpired(true);
            });
    }, []);

    const onFinish = (password: string, setErrorMessage: (e: string) => void) => {
        axios.put(ENDPOINTS.passwordUpdate(linkHash || ''), {
            password: password
        }).then((_) => {
            navigate('/password/update/done');
        }).catch(_ => {
            setErrorMessage('Fehlgeschlagen.');
        });
    };

    return <CenterModal showLogo={true}>
        <Space direction="vertical"
               style={{width: '100%', padding: 16, boxSizing: 'border-box', alignItems: 'stretch'}}>
            {!expired ? <>
                <h1 style={{marginBottom: 8}}>Admin Panel</h1>
                <h2 style={{marginTop: 0}}>Passwort zurücksetzen</h2>

                <PasswordForm onFinish={onFinish}/>
            </> : <>
                <h3>Der Link zum Zurücksetzen des Passwortes ist leider ausgelaufen.</h3>

                <Button
                    type="primary"
                    onClick={() => navigate('/')}
                >Zurück zum Login</Button>
            </>}
        </Space>
    </CenterModal>;
};