import React from 'react';
import {Select} from 'antd';

export interface SelectApiSearchProps {
    selectFetch: (search: string) => Promise<{ value: string, label: string }[]>,
    onChange: (value: string) => void,
    delayMs?: number
}

export const SelectApiSearch = (props: SelectApiSearchProps) => {
    const [options, setOptions] = React.useState<{ label: string, value: string }[]>([]);
    const [fieldSearch, setFieldSearch] = React.useState<string>('');
    const [newSearch, setNewSearch] = React.useState<boolean>(false);

    React.useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            props.selectFetch(fieldSearch)
                .then(setOptions);
        }, props.delayMs || 50);
        return () => clearTimeout(delayDebounceFn);
    }, [fieldSearch]);

    let newOptions = options.slice(0, 6);
    if (newSearch) {
        newOptions = newOptions.concat([{
            label: '-',
            value: '',
        }]);
    } else {
        newOptions = ([{
            label: '-',
            value: '',
        }]).concat(newOptions);
    }

    return <Select
        showSearch
        options={newOptions}
        placeholder="Suche"
        filterOption={false}
        onSearch={s => {
            setNewSearch(true);
            setFieldSearch(s);
        }}
        onChange={newValue => {
            setNewSearch(false);
            props.onChange(newValue);
        }}
        style={{flexGrow: 1}}
        defaultValue={''}
    />;
};