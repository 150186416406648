import {Space} from 'antd';
import {AutoFetchTable} from '../../components/table/AutoFetchTable';
import {ENDPOINTS} from '../../constants/ApiEndpoints';
import Column from 'antd/es/table/Column';
import React from 'react';
import {CompanyStat} from '../../interfaces/CompanyStat';
import {RenderHelper} from '../../util/renderHelper';
import {Picker, TimeSpanPicker} from './components/TimeSpanPicker';
import {DetailStat} from './components/DetailStat';

export const CompanyStats = () => {
    const invalidate = React.useRef<() => void>();
    const [timeSpan, setTimeSpan] = React.useState<[string, string]>();
    const [picker, setPicker] = React.useState<Picker>('date');

    const transformStats = (c: CompanyStat) => {
        const addr = c.company.addresses[0];
        return {
            ...c,
            cityText: (addr?.city || '') + ', ' + addr?.zip || '',
            countryText: addr?.country || '',
        };
    };

    React.useEffect(() => {
        invalidate.current && invalidate.current();
    }, [timeSpan]);

    return <Space direction="vertical" size="large" style={{width: '100%'}}>
        <div>
            <h1>Umsätze, Verkäufer</h1>
            <TimeSpanPicker defaultPicker='month' onChange={(s, p) => {
                setTimeSpan(s);
                setPicker(p);
            }}/>
        </div>
        <AutoFetchTable
            invalidateCallback={invalidate}
            url={ENDPOINTS.adminListCompanyStats}
            query={timeSpan ? {
                from: timeSpan[0],
                until: timeSpan[1],
            } : {}}
            modal={(item, onClose) => <DetailStat
                onClose={onClose}
                initialDateRange={[timeSpan ?? ['', ''], picker]}
                company={item.company}
            />}
            transformFunction={transformStats}
            searchItems={[
                {value: 'searchName', label: 'Name'}
            ]}>
            <Column title="Name" dataIndex={['company', 'name']} key="company.name" sorter={true}/>
            <Column title="Stadt" dataIndex="cityText" key="cityTextc"/>
            <Column title="Land" dataIndex="countryText" key="countryText"/>
            <Column title="Netto Umsatz" align={'right'} dataIndex={'soldNetVolume'} key="soldNetVolume" sorter={true}
                    render={RenderHelper.cents}/>
            <Column title="Verkaufte Artikel" align={'right'} dataIndex={'soldNumberOfItems'} key="soldNumberOfItems"
                    sorter={true}/>
            <Column title="Bestellungen" align={'right'} dataIndex={'soldNumberOfOrders'} key="soldNumberOfOrders"
                    sorter={true}/>
        </AutoFetchTable>
    </Space>;
};