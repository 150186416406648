import React from 'react';
import {Space, Tag} from 'antd';
import {ENDPOINTS} from '../../constants/ApiEndpoints';
import {Company} from '../../interfaces/Company';
import {EditCompany} from './components/EditCompany';
import {AutoFetchTable} from '../../components/table/AutoFetchTable';
import Column from 'antd/es/table/Column';


export const ManageCompanies = () => {
    const transformCompany = (c: Company) => {
        const addr = c.addresses[0];
        return {
            cityText: addr?.city || '',
            zipText: addr?.zip || '',
            countryText: addr?.country || '',
            ...c
        };
    };

    return <Space direction="vertical" size="large" style={{width: '100%'}}>
        <h1>Unternehmen</h1>

        <AutoFetchTable
            url={ENDPOINTS.adminListCompanies}
            modal={(item, onClose) => <EditCompany company={item} onClose={onClose}/>}
            transformFunction={transformCompany}
            searchItems={[
                {value: 'searchName', label: 'Name'}
            ]}>
            <Column title="Name" dataIndex="name" key="name" sorter={true}/>
            <Column title="Rolle" dataIndex="buyer" key="name"
                    render={(_: any, company: Company) => (
                        <Space>
                            {company.buyer ? <Tag color="orange">Käufer</Tag> : <></>}
                            {company.seller ? <Tag color="purple">Verkäufer</Tag> : <></>}
                        </Space>
                    )}
            />
            <Column title="Stadt" dataIndex="cityText" key="cityText"/>
            <Column title="Postleitzahl" dataIndex="zipText" key="zip"/>
            <Column title="Land" dataIndex="countryText" key="countryText"/>
        </AutoFetchTable>
    </Space>;
};