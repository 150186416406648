import {CategoryHierarchy, CategoryStatus, FilterItem, FilterSource} from '../../../interfaces/Category';
import {ENDPOINTS} from '../../../constants/ApiEndpoints';
import {UpdateModal} from '../../../components/input/UpdateModal';
import {Form, Input, Select, Tag, Tooltip, TreeSelect} from 'antd';
import React from 'react';
import {ImageUpload} from '../../../components/input/ImageUpload';
import {Filter} from '../../../interfaces/Filter';
import {fetchAll} from '../../../util/fetchPaged';
import {useErrorHandling} from '../../../components/error/ErrorHandlerProvider';

export interface EditCategoryProps {
    category?: CategoryHierarchy,
    defaultParent?: string | null
    onClose?: () => void
    fullHierarchy: CategoryHierarchy[],
}

type SelectionTree = {
    title: string,
    value: string | null,
    children: SelectionTree[],
}

export const renderCategoryFilter = (item: FilterItem) => {
    let color = 'green';
    let text = 'Eigener Filter';

    if (item.filterSource == FilterSource.ABOVE) {
        color = 'blue';
        text = 'Geerbter Filter von überliegender Kategorie';
    } else if (item.filterSource == FilterSource.BELLOW) {
        color = 'orange';
        text = 'Geerbter Filter von unterliegender Kategorie';
    }

    return <Tooltip title={text}><Tag color={color}>{item.name}</Tag></Tooltip>;
};

const InheritedFilters = (props: { category: CategoryHierarchy }) => {
    const above = props.category.filters.filter(f => f.filterSource == FilterSource.ABOVE);
    const bellow = props.category.filters.filter(f => f.filterSource == FilterSource.BELLOW);

    return <>
        {above.length > 0 ? <p>Von Überkategorien geerbte Filter: {above.map(renderCategoryFilter)}</p> : <></>}
        {bellow.length > 0 ? <p>Von Unterkategorien geerbte Filter: {bellow.map(renderCategoryFilter)}</p> : <></>}
    </>;
};


export const EditCategory = (props: EditCategoryProps) => {
    const [allFilters, setAllFilters] = React.useState<Filter[]>([]);
    const [showInherited, setShowInherited] = React.useState<boolean>(!!props.category);

    const axiosErrorHandler = useErrorHandling();

    React.useEffect(() => {
        fetchAll<Filter>({url: ENDPOINTS.adminListFilter})
            .then((e) => setAllFilters(e.fetched))
            .catch(axiosErrorHandler);
    }, []);

    const defaultParentId = props.category?.parentId ?? props.defaultParent ?? null;

    const [method, target]: ['PUT' | 'POST', string] = props.category ?
        ['PUT', ENDPOINTS.adminUpdateCategory(props.category)] :
        ['POST', ENDPOINTS.adminCreateCategory];

    function mapToOptions(h: CategoryHierarchy[]): SelectionTree[] {
        return h.filter(h => h.id != props.category?.id).map(h => ({
            title: h.name,
            value: h.id,
            children: mapToOptions(h.children || [])
        }));
    }

    const options: SelectionTree[] = mapToOptions(props.fullHierarchy);
    options.unshift({
        title: '-',
        value: '',
        children: [],
    });
    
    return <UpdateModal item={{
        name: props.category?.name || '',
        status: props.category?.status || CategoryStatus.ACTIVE,
        parentId: defaultParentId,
        filterIds: props.category?.filters.filter((f) => f.filterSource == FilterSource.OWN).map((f) => f.id) || []
    }}
                        onClose={props.onClose}
                        title={'Kategorie'}
                        method={method}
                        targetUrl={target}>
        <Form.Item
            name="name"
            label="Name"
            rules={[
                {required: true},
            ]}
        >
            <Input/>
        </Form.Item>

        <Form.Item
            name="parentId"
            label="Übergeordnete Kategorie"
            rules={[]}
        >
            <TreeSelect
                onChange={(_) => setShowInherited(false)}
                showSearch
                treeDefaultExpandedKeys={[defaultParentId || '']}
                treeData={options}
            ></TreeSelect>
        </Form.Item>

        <Form.Item
            name="image"
            label="Bild"
            rules={[
                {required: !props.category},
            ]}
        >
            <ImageUpload initialImageId={props.category?.imageId} name={'Icon'}/>
        </Form.Item>

        <Form.Item name="filterIds" label="Filter" rules={[]}>
            <Select
                mode="multiple"
                /*  filterOption is needed. Per default value is search. 🤦*/
                filterOption={(input, option) => (option?.label.toLowerCase().includes(input.toLowerCase()) ?? false)}
                options={allFilters.map((f) => ({value: f.id, label: f.name}))}
            ></Select>
        </Form.Item>

        {showInherited && props.category ? <InheritedFilters category={props.category}/> : <></>}

        <Form.Item name="status" label="Status" rules={[
            {required: true},
        ]}>
            <Select
                options={[
                    {value: CategoryStatus.ACTIVE, label: 'Aktiv'},
                    {value: CategoryStatus.HIDDEN, label: 'Versteckt'},
                ]}
            ></Select>
        </Form.Item>

    </UpdateModal>;
};