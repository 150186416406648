import React, {ReactNode} from 'react';

import './center-modal.css';

export interface CentralModelProps {
    showLogo?: boolean,
    children: ReactNode
}

export const CenterModal = (props: CentralModelProps) => {
    return <div className="center-modal">
        {props.showLogo ? <img src="/img/inopartLogo.jpeg" className="center-modal-logo" alt="inopart logo"/> : <></>}
        <div className="center-modal-inner">
            {props.children}
        </div>
    </div>;
};