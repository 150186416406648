import {Company} from '../../../interfaces/Company';
import {Button, Col, Form, Input, Row, Select, Space, Switch} from 'antd';
import React from 'react';
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import {CountiesISO3166_1} from '../../../data/country';
import {ENDPOINTS} from '../../../constants/ApiEndpoints';
import {UpdateModal} from '../../../components/input/UpdateModal';
import {ImageUpload} from '../../../components/input/ImageUpload';

export interface EditCompanyProps {
    company: Company,
    onClose?: () => void,
}


export const EditCompany = (props: EditCompanyProps) => {

    return <UpdateModal item={props.company}
                        onClose={props.onClose}
                        title={'Hersteller'}
                        method={'PUT'}
                        targetUrl={ENDPOINTS.adminUpdateCompany(props.company)}>
        <Space direction="vertical">

            <Form.Item label="Unternehmensname" name="name" rules={[{required: true}]}>
                <Input/>
            </Form.Item>

            <Form.Item
                name="ImageData"
                label="Unternehmenslogo"
            >
                <ImageUpload initialImageId={props.company.imageId}/>
            </Form.Item>


            <Row>
                <Form.Item label="Verkäufer" name="seller" valuePropName="checked">
                    <Switch/>
                </Form.Item>

                <Form.Item label="Käufer" name="buyer" valuePropName="checked">
                    <Switch/>
                </Form.Item>
            </Row>

            <p>Adressen</p>

            <Form.List name="addresses">{(fields, {add, remove}, _) => (
                <div>
                    {fields.map((_address, index) => (
                        <Form.Item noStyle key="address">
                            <Row style={{
                                border: '2px #EEE dashed',
                                padding: 8,
                                borderRadius: 8,
                                marginBottom: 24,
                            }}>
                                <Col span={22}>
                                    <Form.Item name={[index, 'titel']}>
                                        <Input placeholder="Standort-Name"/>
                                    </Form.Item>
                                </Col>
                                <Col span={2}>
                                    <Button danger
                                            icon={<MinusCircleOutlined/>}
                                            onClick={() => remove(index)}
                                            style={{width: '100%'}}
                                            disabled={fields.length == 1}
                                    />
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="Land" name={[index, 'country']} rules={[{required: true}]}>
                                        <Select options={CountiesISO3166_1} placeholder="Land"/>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="Postleitzahl" name={[index, 'zip']} rules={[{required: true}]}>
                                        <Input placeholder="Postleitzahl"/>
                                    </Form.Item>
                                </Col>
                                <Col span={18}>
                                    <Form.Item label="Stadt" name={[index, 'city']} rules={[{required: true}]}>
                                        <Input placeholder="Stadt"/>
                                    </Form.Item>
                                </Col>
                                <Col span={18}>
                                    <Form.Item label="Straße" name={[index, 'street']} rules={[{required: true}]}>
                                        <Input placeholder="Straße"/>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="Hausnummer" name={[index, 'streetNumber']}
                                               rules={[{required: true}]}>
                                        <Input placeholder="Hausnummer"/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form.Item>
                    ))}
                    <Button
                        type="dashed"
                        onClick={() => add()}
                        style={{width: '100%'}}
                        icon={<PlusOutlined/>}
                    >
                        Addresse hinzufügen
                    </Button>
                </div>
            )}</Form.List>
        </Space>
    </UpdateModal>;
};