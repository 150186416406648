import {DatePicker, Segmented, Space} from 'antd';
import React from 'react';
import dayjs from 'dayjs';

export type Picker = 'date' | 'week' | 'month' | 'year';
type TimeSpanPickerProps = {
    onChange?: (range: [string, string], picker: Picker) => void,
    defaultPicker?: Picker
    defaultDates?: [string, string]
}

export const TimeSpanPicker = (props: TimeSpanPickerProps) => {
    const [picker, setPicker] = React.useState(props.defaultPicker ?? 'date');
    const [timeSpan, setTimeSpan] = React.useState(props.defaultDates ? dayjs(props.defaultDates[0]) : dayjs());

    React.useEffect(() => {
        if (!props.onChange) return;
        props.onChange([timeSpan.startOf(picker).format(), timeSpan.endOf(picker).format()], picker);
    }, [picker, timeSpan]);

    const options = [
        {
            value: 'date',
            label: 'Tag'
        },
        {
            value: 'week',
            label: 'Woche'
        },
        {
            value: 'month',
            label: 'Monat'
        },
        {
            value: 'quarter',
            label: 'Quartal'
        },
        {
            value: 'year',
            label: 'Jahr'
        }];

    return <Space.Compact>
        <Segmented options={options.reverse()} value={picker} onChange={v => {
            setPicker(v as any);
        }}/>
        <DatePicker picker={picker as any} onChange={(t) => {
            if (t) setTimeSpan(t);
        }} value={timeSpan}/>
    </Space.Compact>;
};