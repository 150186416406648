import React from 'react';
import {ENDPOINTS} from '../../constants/ApiEndpoints';
import {CategoryHierarchy, CategoryStatus, FilterSource} from '../../interfaces/Category';
import {Button, Space, Table, Tag} from 'antd';
import Column from 'antd/es/table/Column';
import axios from 'axios';
import {useErrorHandling} from '../../components/error/ErrorHandlerProvider';
import {EditCategory, renderCategoryFilter} from './component/EditCategory';
import {EditOutlined, PlusOutlined, PlusSquareOutlined} from '@ant-design/icons';
import {Searchbar} from '../../components/search/Searchbar';


export const ManageCategories = () => {
    const axiosErrorHandler = useErrorHandling();

    const [allCategories, setAllCategories] = React.useState<CategoryHierarchy[]>([]);
    const [editCat, setEditCat] = React.useState<CategoryHierarchy>();
    const [createCat, setCreateCat] = React.useState<{ parentId: string | null }>();
    const [query, setQuery] = React.useState<{ [v: string]: string }>({});
    const [sorter, setSorter] = React.useState<{ field: string, reverse: boolean }>();

    const fetchCategories = () => {
        axios.get(ENDPOINTS.adminListCategory)
            .then((e) => setAllCategories(e.data))
            .catch(axiosErrorHandler);
    };

    function filterTree(roots: CategoryHierarchy[], fn: (c: CategoryHierarchy) => boolean): CategoryHierarchy[] {
        return roots.map(h => ({
            ...h,
            children: filterTree(h.children || [], fn),
        })).filter(h => fn(h) || (h.children.length > 0)).map(h => ({
            ...h,
            children: h.children || undefined
        }));
    }

    function treeToIds(roots: CategoryHierarchy[]): string[] {
        return roots.flatMap(h => [h.id].concat(treeToIds(h.children || [])));
    }

    function sortBy(roots: CategoryHierarchy[], fn: (h: CategoryHierarchy) => string, reverse: boolean) {
        roots.sort((a, b) => {
            const aName = (fn(a)).toLowerCase();
            const bName = (fn(b) + '').toLowerCase();
            const rev = reverse ? -1 : 1;

            if (aName > bName) {
                return -1 * rev;
            }
            if (aName < bName) {
                return 1 * rev;
            }
            return 0;
        });

        for (const r of roots) {
            sortBy(r.children || [], fn, reverse);
        }
    }

    let filteredCategories = allCategories;
    let searchActive = false;
    if (query['name']) {
        filteredCategories = filterTree(filteredCategories, (h) => {
            return h.name.toLowerCase().includes(query['name'].toLowerCase());
        });
        searchActive = true;
    }
    if (query['filter']) {
        filteredCategories = filterTree(filteredCategories, (h) => {
            return !!h.filters.find(f => f.name.toLowerCase().includes(query['filter'].toLowerCase()));
        });
        searchActive = true;
    }

    if (sorter) {
        sortBy(filteredCategories, (h) => (h as { [v: string]: any })[sorter.field] + '', sorter.reverse);
    }

    React.useEffect(() => {
        fetchCategories();
    }, []);

    return <Space direction="vertical" size="large" style={{width: '100%'}}>
        <h1>Kategorien</h1>
        <div>
            {editCat ? <EditCategory fullHierarchy={allCategories} onClose={() => {
                setEditCat(undefined);
                setCreateCat(undefined);
                fetchCategories();
            }} category={editCat}/> : <></>}
            {createCat ? <EditCategory fullHierarchy={allCategories} onClose={() => {
                setEditCat(undefined);
                setCreateCat(undefined);
                fetchCategories();
            }} defaultParent={createCat.parentId}/> : <></>}
            <Button
                type="primary"
                icon={<PlusOutlined/>}
                onClick={() => setCreateCat({parentId: null})}
            >Kategorie hinzufügen</Button>
        </div>
        <Searchbar items={[{label: 'Name', value: 'name'}, {label: 'Filter', value: 'filter'}]}
                   onChange={setQuery}
                   delayMs={1}
        />
        <Table
            expandable={{
                expandedRowKeys: searchActive ? treeToIds(filteredCategories) : undefined
            }}
            onChange={(page, filter, sorter) => {
                if (!Array.isArray(sorter)) {
                    setSorter({field: sorter.field + '', reverse: sorter.order == 'ascend'});
                }
            }}
            dataSource={filteredCategories}
            pagination={false}
            rowKey="id">
            <Column title="Name" dataIndex="name" key="name" sorter={true}/>
            <Column title="Icon" dataIndex="imageId" key="imageId"
                    render={(_: any, m: CategoryHierarchy) => {
                        return <img src={ENDPOINTS.image(m)} alt={m.name.substring(0, 5) + '. Logo'}
                                    width={50} height={25}
                                    style={{objectFit: 'contain'}}/>;
                    }}
            />
            <Column title="Eigene Filter" dataIndex="filters" key="filters"
                    render={(_: any, m: CategoryHierarchy) => {
                        return m.filters.filter(f => f.filterSource == FilterSource.OWN).map(renderCategoryFilter);
                    }}/>
            <Column title="Geerbte Filter" dataIndex="filters" key="filters"
                    render={(_: any, m: CategoryHierarchy) => {
                        return m.filters.filter(f => f.filterSource != FilterSource.OWN).map(renderCategoryFilter);
                    }}/>
            <Column title="Status" dataIndex="status" key="status" sorter={true}
                    render={(_: any, m: CategoryHierarchy) => {
                        if (m.status == CategoryStatus.ACTIVE) {
                            return <Tag color={'green'}>Aktiv</Tag>;
                        } else if (m.status == CategoryStatus.HIDDEN) {
                            return <Tag color={'red'}>Ausgeblendet</Tag>;
                        } else {
                            return <Tag>???</Tag>;
                        }
                    }}/>
            <Column title="" dataIndex="id" key="actions"
                    render={(_: any, item: CategoryHierarchy) => (
                        <>
                            <Space>
                                <EditOutlined onClick={() => {
                                    setEditCat(item);
                                    setCreateCat(undefined);
                                }}/>
                                <PlusSquareOutlined onClick={() => {
                                    setEditCat(undefined);
                                    setCreateCat({parentId: item.id});
                                }}/>
                            </Space>
                        </>
                    )}
            />
        </Table>

    </Space>;
};