import {Button, Input} from 'antd';
import {EyeInvisibleTwoTone, EyeTwoTone, LockOutlined, UserOutlined} from '@ant-design/icons';
import React from 'react';

import './login.css';
import {useNavigate} from 'react-router-dom';
import {useAuth} from '../../auth/AuthProvider';
import {CenterModal} from '../../components/layout/CenterModal';

export const Login = () => {
    const navigate = useNavigate();
    const auth = useAuth();

    const [passwordVisible, setPasswordVisible] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(undefined as string | undefined);

    const [mail, setMail] = React.useState('');
    const [password, setPassword] = React.useState('');

    // Redirect if already logged in
    React.useEffect(() => {
        if (auth.loggedIn) {
            navigate('/dashboard');
        }
    });

    const onLogin = async () => {
        const res = await auth.login(mail.trim(), password.trim());
        if (res.ok) {
            setErrorMessage(undefined);
            navigate('/dashboard');
        } else {
            setErrorMessage(res.message || 'Login fehlgeschlagen.');
        }
    };

    return <CenterModal showLogo={true}>
        <form className="login-inner">
            <h1>Admin-Panel</h1>
            <Input
                value={mail}
                onChange={(e) => {
                    setMail(e.target.value);
                    setErrorMessage('');
                }}
                size="large"
                prefix={<UserOutlined/>}
            />
            <br/>
            <Input.Password
                value={password}
                onChange={(e) => {
                    setPassword(e.target.value);
                    setErrorMessage('');
                }}
                size="large"
                prefix={<LockOutlined/>}
                iconRender={(visible) => (visible ? <EyeTwoTone/> : <EyeInvisibleTwoTone/>)}
                visibilityToggle={{visible: passwordVisible, onVisibleChange: setPasswordVisible}}
            />
            <div style={{
                display: errorMessage ? 'inherit' : 'none',
            }} className="login-error-message">
                {(errorMessage || '')}
            </div>
            <br/>
            <Button className="login-button" onClick={onLogin}>Login</Button>
        </form>
    </CenterModal>;
};