const euroFormat = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR'
});

const numberFormat = new Intl.NumberFormat('de-DE');
const dateTimeFormat = new Intl.DateTimeFormat('de-DE');

export const RenderHelper = {
    cents: (v: number) => euroFormat.format(v / 100),
    dateTime: (v: string) => new Date(v).toLocaleString('de-de'),
    date: (v: string) => dateTimeFormat.format(new Date(v)),
    number: (v: number) => numberFormat.format(v),
};
