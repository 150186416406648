import {Filter} from './Filter';

export type CategoryHierarchy = {
    id: string
    parentId: null | string
    name: string
    status: CategoryStatus
    imageId: string
    children?: CategoryHierarchy[]
    filters: FilterItem[]
};

export type FilterItem = Filter & {
    filterSource: FilterSource
}

export enum FilterSource {
    OWN = 'OWN',
    BELLOW = 'BELLOW',
    ABOVE = 'ABOVE',
}

export enum CategoryStatus {
    ACTIVE = 'ACTIVE',
    HIDDEN = 'HIDDEN',
}