import {App, Button, Space, Tag} from 'antd';
import {AutoFetchTable} from '../../components/table/AutoFetchTable';
import {ENDPOINTS} from '../../constants/ApiEndpoints';
import Column from 'antd/es/table/Column';
import React, {useRef} from 'react';
import {Manufacturer, ManufacturerState} from '../../interfaces/Manufacturer';
import {EditManufacturer} from './component/EditManufacturer';
import {PlusOutlined} from '@ant-design/icons';
import {useErrorHandling} from '../../components/error/ErrorHandlerProvider';
import axios from 'axios';

export const ManageManufacturer = () => {
    const {notification, modal} = App.useApp();
    const handleErrors = useErrorHandling();

    const [displayCreate, setDisplayCreate] = React.useState(false);
    const invalidateCallback = useRef<() => void>();

    const transformUser = (c: Manufacturer) => {
        return {
            ...c
        };
    };

    const withConfirm = (text: string, action: (u: Manufacturer) => Promise<void>, content?: string) => (u: Manufacturer) => {
        modal.confirm({
            title: text,
            content: content ?? `${u.name}`,
            okText: 'Ja',
            cancelText: 'Abbrechen',
            onOk: () => {
                action(u)
                    .catch(handleErrors);
            }
        });
    };

    const deleteManufacturer = withConfirm(
            'Hersteller löschen?',
            async (m: Manufacturer) => {
                await axios.delete(ENDPOINTS.adminDeleteManufacturer(m));
                notification.info({
                    message: 'Hersteller wurde gelöscht'
                });
                if (invalidateCallback.current) invalidateCallback.current();
            },
            ''
        );

    return <Space direction="vertical" size="large" style={{width: '100%'}}>
        <h1>Hersteller</h1>

        <div>
            {displayCreate ? <EditManufacturer onClose={() => {
                setDisplayCreate(false);
                if (invalidateCallback.current) invalidateCallback.current();
            }}/> : <></>}
            <Button
                type="primary"
                icon={<PlusOutlined/>}
                onClick={() => setDisplayCreate(true)}
            >Hersteller hinzufügen</Button>
        </div>

        <AutoFetchTable
            invalidateCallback={invalidateCallback}
            url={ENDPOINTS.adminListManufacturer}
            modal={(item, onClose) => <EditManufacturer manufacturer={item} onClose={onClose}/>}
            transformFunction={transformUser}
            query={{}}
            searchItems={[
                {value: 'searchName', label: 'Name'},
                {
                    value: 'manufacturerState', label: 'Status', select: [
                        {value: ManufacturerState.ManufacturerActive, label: 'Aktiv'},
                        {value: ManufacturerState.ManufacturerUnchecked, label: 'Nicht überprüft'},
                        {value: ManufacturerState.ManufacturerHidden, label: 'Ausgeblendet'},
                    ]
                }
            ]}
        extraActions={[
            {
                label: 'Löschen',
                callback: deleteManufacturer,
                condition: (_) => true,
            }
        ]}>
            <Column title="Name" dataIndex="name" key="name" sorter={true}/>
            <Column title="Bild" dataIndex="imageId" key="imageId"
                    render={(_: any, m: Manufacturer) => {
                        return <img src={ENDPOINTS.image(m)} alt={m.name + ' Logo'}
                                    width={50} height={25}
                                    style={{objectFit: 'contain'}}/>;
                    }}
            />
            <Column title="Anzahl der Produkte" dataIndex="countProducts" key="countProducts" sorter={true}/>
            <Column title="Status" dataIndex="manufacturerState" key="manufacturerState" sorter={true}
                    render={(_: any, m: Manufacturer) => {
                        if (m.manufacturerState == ManufacturerState.ManufacturerHidden) {
                            return <Tag>Ausgeblendet </Tag>;
                        } else if (m.manufacturerState == ManufacturerState.ManufacturerUnchecked) {
                            return <Tag color='red'>Nicht Überprüft </Tag>;
                        } else if (m.manufacturerState == ManufacturerState.ManufacturerActive) {
                            return <Tag color="green">Aktiv</Tag>;
                        } else {
                            return <Tag>??</Tag>;
                        }
                    }}/>
        </AutoFetchTable>
    </Space>;
};