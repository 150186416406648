import React from 'react';
import {User, UserState} from '../../../interfaces/User';
import {Button, Checkbox, Col, Form, Input, InputNumber, Modal, Row, Select, Space, Switch} from 'antd';
import axios from 'axios';
import {ENDPOINTS} from '../../../constants/ApiEndpoints';
import {PermissionGroup} from '../../../interfaces/Permission';
import {fetchAll} from '../../../util/fetchPaged';
import {useErrorHandling} from '../../../components/error/ErrorHandlerProvider';

export interface EditUserProps {
    user: User,
    onClose?: () => void,
}


export const EditUser = (props: EditUserProps) => {
    const handleErrors = useErrorHandling();

    const validateMessages = {
        required: '${label} wird benötigt!',
    };

    const user = props.user;

    const [permissionGroups, setPermissionGroups] = React.useState<PermissionGroup[]>([]);
    const [otherUser, setOtherUsers] = React.useState<User[]>([]);
    const [unlimitedBudget, setUnlimitedBudget] = React.useState(user.unlimitedBudget);

    React.useEffect(() => {
        if (!user.company) {
            throw new Error('User has no Company.');
        }

        fetchAll<PermissionGroup>({
            url: ENDPOINTS.adminCompanyPermissionGroup(user.company),
        }).then((state) => {
            setPermissionGroups(state.fetched);
        }).catch(handleErrors);

        fetchAll<User>({
            url: ENDPOINTS.adminCompanyUsers(user.company),
        }).then((state) => {
            setOtherUsers(state.fetched);
        }).catch(handleErrors);
    }, [user]);

    const onFinish = (values: any) => {
        console.log(values);
        console.log(unlimitedBudget);
        axios.put(ENDPOINTS.adminUpdateUser(user), {
            ...values,
            budget: unlimitedBudget ? 0 : Math.round(values.budget * 100),
            budgetMonthly: unlimitedBudget ? 0 : Math.round(values.budgetMonthly * 100),
            unlimitedBudget: unlimitedBudget
        })
            .then(props.onClose).catch(handleErrors);
    };

    const supervisors = [{label: '-', value: ''}].concat(otherUser.filter((u) => u.id != user.id).map((p) => ({
        label: p.firstName + ' ' + p.lastName,
        value: p.id
    })));

    return <Modal
        open={true}
        onCancel={props.onClose}
        footer={null}
    >
        <h3>Nutzerdaten</h3>
        <Form
            onFinish={onFinish}
            layout="vertical"
            validateMessages={validateMessages}
            initialValues={{
                ...user,
                supervisorId: user.supervisorId || '',
                budget: user.budget / 100, // CENT -> EUR
                budgetMonthly: user.budgetMonthly / 100,
            }}
        >
            <Space direction="vertical">
                <Row>
                    <Col span={18}>
                        <Form.Item label="E-Mail" name="mail" rules={[{type: 'email', required: true}]}>
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={4} offset={2}>

                        <Form.Item name="newsletter" label="Newsletter">
                            <Switch/>
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item label="Name" style={{marginBottom: 2}}>
                    <Form.Item
                        label="Vorname"
                        name="firstName"
                        noStyle
                        rules={[{required: true}]}
                    >
                        <Input style={{width: '50%'}} placeholder="Vorname"/>
                    </Form.Item>
                    <Form.Item
                        label="Nachname"
                        name="lastName"
                        noStyle
                        rules={[{required: true}]}
                    >
                        <Input style={{width: '50%'}} placeholder="Nachname"/>
                    </Form.Item>
                </Form.Item>

                <Form.Item label="Abteilung" name="department" style={{marginBottom: 2}}>
                    <Input placeholder="Abteilung"/>
                </Form.Item>
                <Form.Item label="Kommissionierungsnummer" name="pickingNumber">
                    <Input placeholder="Kommissionierungsnummer"/>
                </Form.Item>

                <Row>
                    <Col span={11}>
                        <Form.Item label="Budget" name="budget" style={{
                            marginBottom: 4
                        }}>
                            <InputNumber step={0.01} min={0} placeholder="" addonAfter="EUR"
                                         disabled={unlimitedBudget}/>
                        </Form.Item>
                        <Form.Item name="unlimitedBudged">
                            <Checkbox
                                checked={unlimitedBudget}
                                onChange={(e) => setUnlimitedBudget(e.target.checked)}
                            >Unlimitiertes Budget</Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={11} offset={2}>
                        <Form.Item name="budgetMonthly" label="Monatliches Budget">
                            <InputNumber step={0.01} min={0} type="number" placeholder="" addonAfter="EUR"
                                         value={unlimitedBudget ? 0.0 : undefined}
                                         disabled={unlimitedBudget}/>
                        </Form.Item>
                    </Col>
                </Row>
                
                <Form.Item name="permissionGroupId" label="Berechtigungsgruppe" style={{marginBottom: 2}}>
                    <Select
                        options={permissionGroups.map((p) => ({
                            label: p.name,
                            value: p.id
                        }))}
                    ></Select>
                </Form.Item>

                <Form.Item name="supervisorId" label="Vorgesetzter" style={{marginBottom: 2}}>
                    <Select
                        options={supervisors}
                    ></Select>
                </Form.Item>


                <Form.Item name="userState" label="Status">
                    <Select
                        options={[
                            {value: UserState.Active, label: 'Aktiv'},
                            {value: UserState.Blocked, label: 'Gesperrt'},
                            {value: UserState.Invited, label: 'Eingeladen'},
                        ]}
                    ></Select>
                </Form.Item>

                <Space style={{width: '100%', justifyContent: 'flex-end'}}>
                    <Button onClick={props.onClose}>
                        Abbrechen
                    </Button>

                    <Button type="primary" htmlType="submit">
                        Fertig
                    </Button>
                </Space>
            </Space>
        </Form>
    </Modal>;
};