import {User} from '../interfaces/User';
import {Company} from '../interfaces/Company';
import {Manufacturer} from '../interfaces/Manufacturer';
import {Filter} from '../interfaces/Filter';

export const ENDPOINTS = {
    login: 'auth/web/login',
    getCurrentUser: 'user',

    inviteUser: 'auth/invite',
    blockUser: (u: User) => `user/${u.id}/block`,
    unblockUser: (u: User) => `user/${u.id}/unblock`,
    listUsers: (u: User) => `companies/${u.companyId}/users`,
    user: (u: User) => `user/${u.id}`,
    permissionGroups: 'permissionGroups',
    passwordReset: 'password/reset',
    finishAppRegistration: 'auth/invite/finish',
    passwordResetInfo: (link: string) => 'password/reset/info/' + link,
    passwordUpdate: (link: string) => 'password/update/' + link,
    image: (c: { imageId: string }) => (process.env.REACT_APP_API_URL || '') + `/image/${c.imageId}`,
    //
    adminListCompanies: 'admin/company/',
    adminListCompanyStats: 'admin/companyStat/',
    adminGetCompanyStats: (c: Company) => `/admin/companyStat/${c.id}`,
    adminGetCompanies: (id: string) => 'admin/company/' + id,
    adminUpdateCompany: (c: Company) => `/admin/company/${c.id}`,
    adminCompanyPermissionGroup: (c: Company) => `/admin/company/${c.id}/permissionGroup/`,
    adminCompanyUsers: (c: Company) => `/admin/company/${c.id}/user/`,
    //
    adminListUsers: 'admin/user/',
    adminUpdateUser: (c: User) => `admin/user/${c.id}`,
    //
    adminListManufacturer: 'admin/manufacturer/',
    adminCreateManufacturer: 'admin/manufacturer/',
    adminUpdateManufacturer: (m: Manufacturer) => `admin/manufacturer/${m.id}/`,
    adminDeleteManufacturer: (m: Manufacturer) => `admin/manufacturer/${m.id}/`,
    //
    adminListFilter: 'admin/filter/',
    adminCreateFilter: 'admin/filter/',
    adminUpdateFilter: (f: Filter) => `admin/filter/${f.id}/`,
    adminDeleteFilter: (f: Filter) => `admin/filter/${f.id}/`,
    //
    adminListCategory: 'admin/category/',
    adminCreateCategory: 'admin/category/',
    adminUpdateCategory: (m: { id: string }) => `admin/category/${m.id}/`,
    //
    adminListOrderBundles: 'admin/orderBundle/',
    adminGetOrderBundles: (m: { id: string }) => `admin/orderBundle/${m.id}/`,
    adminOrder: 'admin/order/'

};