import {App, Button, Space, Tag} from 'antd';
import {AutoFetchTable} from '../../components/table/AutoFetchTable';
import {ENDPOINTS} from '../../constants/ApiEndpoints';
import Column from 'antd/es/table/Column';
import React, {useRef} from 'react';
import {PlusOutlined} from '@ant-design/icons';
import {Filter} from '../../interfaces/Filter';
import {EditFilter} from './component/EditFilter';
import axios from 'axios';
import {useErrorHandling} from '../../components/error/ErrorHandlerProvider';
import {StaticFilterNames} from '../../constants/StaticFilterNames';

export const ManageFilters = () => {
    const {notification, modal} = App.useApp();
    const handleErrors = useErrorHandling();

    const [displayCreate, setDisplayCreate] = React.useState(false);
    const invalidateCallback = useRef<() => void>();

    const transform = (c: Filter) => {
        return {
            ...c
        };
    };

    const withConfirm = (text: string, action: (f: Filter) => Promise<void>, content?: string) => (f: Filter) => {
        modal.confirm({
            title: text,
            content: content ?? `${f.name}`,
            okText: 'Ja',
            cancelText: 'Abbrechen',
            onOk: () => {
                action(f)
                    .catch(handleErrors);
            }
        });
    };

    const deleteFilter = withConfirm(
        'Filter löschen?',
        async (filter: Filter) => {
            await axios.delete(ENDPOINTS.adminDeleteFilter(filter));
            if (invalidateCallback.current) invalidateCallback.current();
            notification.info({
                message: 'Filter wurde gelöscht'
            });
        },
        ''
    );

    return <Space direction="vertical" size="large" style={{width: '100%'}}>
        <h1>Filter</h1>

        <div>
            {displayCreate ? <EditFilter onClose={() => {
                setDisplayCreate(false);
                if (invalidateCallback.current) invalidateCallback.current();
            }}/> : <></>}
            <Button
                type="primary"
                icon={<PlusOutlined/>}
                onClick={() => setDisplayCreate(true)}
            >Filter hinzufügen</Button>
        </div>

        <AutoFetchTable
            invalidateCallback={invalidateCallback}
            url={ENDPOINTS.adminListFilter}
            modal={(item, onClose) => {
                if (!(item.name === StaticFilterNames.seller || item.name === StaticFilterNames.manufacturer)){
                    return  <EditFilter onClose={onClose} filter={item}/>;
                } else{
                    return null;
               }
            }}
            transformFunction={transform}
            query={{}}
            searchItems={[
                {value: 'searchName', label: 'Name'},
            ]}
            extraActions={[
                {
                    label: 'Löschen',
                    callback: deleteFilter,
                    condition: (item:Filter) => !(item.name === StaticFilterNames.seller || item.name === StaticFilterNames.manufacturer),
                },
            ]}
        >
            <Column title="Name" dataIndex="name" key="name" sorter={true}/>
            <Column title="Einheit" dataIndex="unit" key="unit" sorter={true}/>
            <Column title="Typ" dataIndex="filterType" key="filterType" sorter={true}
                    render={(_: any, m: Filter) => {
                        if (m.filterType == 'PROPERTY') {
                            return <Tag color="blue">Einzelwert</Tag>;
                        } else if (m.filterType == 'RANGE') {
                            return <Tag color='orange'>Spanne</Tag>;
                        } else {
                            return <Tag>??</Tag>;
                        }
                    }}/>
            <Column title="Min." dataIndex="rangeLowerCap" key="rangeLowerCap" sorter={true}/>
            <Column title="Max." dataIndex="rangeUpperCap" key="rangeUpperCap" sorter={true}/>
            <Column title="Relevanz" dataIndex="relevance" key="relevance" sorter={true}/>
        </AutoFetchTable>
    </Space>;
};