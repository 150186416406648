import {fetchPage} from './fetchPaged';
import {Company} from '../interfaces/Company';
import {ENDPOINTS} from '../constants/ApiEndpoints';

export const SelectApiSearchHelper = {
    findCompany: async (name: string) => {
        return fetchPage<Company>({
            url: ENDPOINTS.adminListCompanies, query: {
                searchName: name,
                pageSize: 10,
            }
        }).then(data => {
            return data.fetched.map(c => ({label: c.name, value: c.id}));
        });
    }
};