import React from 'react';
import {App, Space, Tag} from 'antd';
import {AutoFetchTable} from '../../components/table/AutoFetchTable';
import {ENDPOINTS} from '../../constants/ApiEndpoints';
import Column from 'antd/es/table/Column';
import {User, UserState} from '../../interfaces/User';
import {EditUser} from './component/EditUser';
import axios from 'axios';
import {useErrorHandling} from '../../components/error/ErrorHandlerProvider';
import {SelectApiSearchHelper} from '../../util/SelectApiSearchHelper';

export const ManageUsers = () => {
    const {notification, modal} = App.useApp();
    const handleErrors = useErrorHandling();

    const withConfirm = (text: string, action: (u: User) => Promise<void>, content?: string) => (u: User) => {
        modal.confirm({
            title: text,
            content: content ?? `${u.firstName} ${u.lastName}`,
            okText: 'Ja',
            cancelText: 'Abbrechen',
            onOk: () => {
                action(u)
                    .catch(handleErrors);
            }
        });
    };

    const resetPassword = withConfirm(
        'Passwort zurücksetzen?',
        async (user: User) => {
            await axios.post(ENDPOINTS.passwordReset, {mail:user.mail});
            notification.info({
                message: 'E-Mail wurde versendet.'
            });
        },
        'Der Nutzer erhält eine E-Mail.'
    );

    const transformUser = (c: User) => {
        return {
            name: c.firstName + ' ' + c.lastName,
            companyName: (c.company?.name) || '?',
            permissions: (c.permissionGroup?.name) || '?',
            ...c
        };
    };

    return <Space direction="vertical" size="large" style={{width: '100%'}}>
        <h1>Nutzer</h1>

        <AutoFetchTable
            url={ENDPOINTS.adminListUsers}
            modal={(item, onClose) => <EditUser user={item} onClose={onClose}/>}
            transformFunction={transformUser}
            query={{
                isAdmin: 'false',
            }}
            searchItems={[
                {value: 'searchMail', label: 'E-Mail'},
                {value: 'searchName', label: 'Name'},
                {value: 'companyId', label: 'Unternehmen', selectFetch: SelectApiSearchHelper.findCompany},
            ]}
            extraActions={[
                {
                    label: 'Passwort zurücksetzen',
                    callback: resetPassword,
                    condition: (_) => true,
                },
            ]}
        >
            <Column title="Name" dataIndex="name" key="name" sorter={true}/>
            <Column title="E-Mail" dataIndex="mail" key="mail" sorter={true}/>
            <Column title="Unternehmen" dataIndex="companyName" key="company.name" sorter={true}/>
            <Column title="Berechtigungsgruppe" dataIndex="permissions" key="permissionGroup.name" sorter={true}/>
            <Column title="Status" dataIndex="state" key="userState" sorter={true}
                    render={(_: any, user: User) => {
                        if (user.userState == UserState.Active) {
                            return <Tag color="green">Aktiv</Tag>;
                        }
                        if (user.userState == UserState.Blocked) {
                            return <Tag color="red">Gesperrt</Tag>;
                        }
                        if (user.userState == UserState.Invited) {
                            return <Tag color="gray">Eingeladen</Tag>;
                        }
                        return <>???</>;
                    }}/>

        </AutoFetchTable>
    </Space>;
};