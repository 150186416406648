import React, {createContext, useContext} from 'react';
import axios from 'axios';
import {ENDPOINTS} from '../constants/ApiEndpoints';
import {User} from '../interfaces/User';
import {UserPermissions} from '../interfaces/Permission';

export interface AuthContextType {
    user: User | null,
    loginByToken: (token: string) => void;
    login: (mail: string, password: string) => Promise<AuthResult>;
    logout: () => void;
    loggedIn: boolean | null;
}

export const useAuth = () => {
    return useContext(AuthContext);
};

interface Props {
    children: React.ReactNode
}

export type AuthResult = {
    ok: boolean,
    message: string | undefined
}


const AuthContext = createContext<AuthContextType>({
    user: null,
    login: async (_username: string, _password: string) => {
        throw new Error('Not Implemented');
    },
    loginByToken: (token: string) => null,
    logout: () => null,
    loggedIn: false,
});


export const AuthProvider: React.FC<Props> = (props) => {
    const [token, setToken] = React.useState(localStorage.getItem('token'));
    const [loggedIn, setLoggedIn] = React.useState<boolean>(!!token);
    const [user, setUser] = React.useState<User | null>(null);

    React.useEffect(() => {
        if (token) {
            localStorage.setItem('token', token);
            axios.defaults.headers['Authorization'] = 'Bearer ' + token;
            setLoggedIn(true);
        } else {
            localStorage.removeItem('token');
            delete axios.defaults.headers['Authorization'];
            setLoggedIn(false);
        }
    }, [token]);

    const fetchUser = async () => {
        if (!token) {
            setUser(null);
            return;
        }

        try {
            const res = await axios.get(ENDPOINTS.getCurrentUser);
            setUser(res.data);
        } catch (e) {
            console.log(e);
            setLoggedIn(false);
            setUser(null);
        }
    };

    React.useEffect(() => {
        fetchUser().then();
    }, [token]);

    const loginByToken = (token: string) => {
        setToken(token);
    };

    const login = async (mail: string, password: string) => {
        try {
            const res = await axios.post(ENDPOINTS.login, {
                mail,
                password
            });

            const user: User = res.data.user;
            if (!user.permissionGroup?.permissions?.includes(UserPermissions.ADMIN_USE)) {
                return {
                    ok: false,
                    message: 'Sie sind nicht berechtigt sich in das Admin-Portal einzuloggen.'
                };
            }

            const token: string = res.data.token;
            setToken(token);
            return {
                ok: true,
                message: undefined
            };
        } catch (e) {
            setToken('');
            localStorage.removeItem('token');

            return {
                ok: false,
                message: 'Nutzername oder Passwort fehlerhaft.'
            };
        }
    };
    const logout = () => {
        setToken(null);
        return null;
    };


    const value = {
        user,
        login,
        logout,
        loggedIn,
        loginByToken,
    };

    return (
        <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
    );
};