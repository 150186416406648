export type Manufacturer = {
    id: string;
    name: string;
    imageId: string;
    manufacturerState: 'UNCHECKED' | 'ACTIVE' | 'HIDDEN';
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ManufacturerState {
    export const ManufacturerUnchecked = 'UNCHECKED';
    export const ManufacturerActive = 'ACTIVE';
    export const ManufacturerHidden = 'HIDDEN';
}