export type PermissionGroup = {
    id: string
    name: string
    permissions: string[]
    companyId: string
    template: string
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace UserPermissions {
    export const ADMIN_USE = 'ADMIN_USE';
    export const ADMIN_MANAGE_ADMINS = 'COMPANIES.LIST_USERS';
    export const ADMIN_MANAGE_CATEGORIES = 'ADMIN.MANAGE_CATEGORIES';
    export const ADMIN_MANAGE_COMPANIES = 'ADMIN.MANAGE_COMPANIES';
    export const ADMIN_MANAGE_MANUFACTURERS = 'ADMIN.MANAGE_MANUFACTURERS';
    export const ADMIN_MANAGE_USERS = 'ADMIN.MANAGE_USERS';
    export const ADMIN_MANAGE_ORDER = 'ADMIN.MANAGE_ORDERS';
}
