import {Menu, Typography} from 'antd';
import {useLocation, useNavigate} from 'react-router-dom';
import {useAuth} from '../../auth/AuthProvider';
import React from 'react';
import {LogoutOutlined} from '@ant-design/icons';
import {ItemType} from 'antd/es/menu/hooks/useItems';

const {Title} = Typography;

export interface SidebarItem {
    kind: 'Item'
    path: string,
    label: string,
    permission: string,
    icon?: React.ReactNode,
    category?: string
    element: React.ReactNode
}

export interface SidebarHeader {
    kind: 'Header',
    label: string,
    children: SidebarTree[],
}

export type SidebarTree = SidebarItem | SidebarHeader

export interface SidebarProps {
    sidebar: SidebarTree[]
}

export const Sidebar = (props: SidebarProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const auth = useAuth();

    function filterPermissions(tree: SidebarTree[]): ItemType[] {
        return tree.flatMap((item: SidebarTree) => {
            switch (item.kind) {
                case 'Item':
                    if (auth.user?.permissionGroup?.permissions.includes(item.permission)) {
                        return [{
                            key: item.path,
                            label: item.label,
                            icon: item.icon,
                        }];
                    } else {
                        return [];
                    }
                case 'Header': {
                    const children = filterPermissions(item.children);
                    if (children.length > 0) {
                        return [{
                            type: 'group',
                            key: '',
                            label: item.label,
                            icon: null,
                            children: children,
                        }];
                    } else {
                        return [];
                    }
                }
            }
        });

    }

    const items = filterPermissions(props.sidebar);

    const onSelect = (event: { key: string }) => {
        navigate(event.key);
    };

    const logout = () => {
        auth.logout();
        navigate('/');
    };

    return (<div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%'}}>
        <Menu
            items={items}
            selectedKeys={[location.pathname]}
            onSelect={onSelect}
        />

        <Menu
            items={[
                {
                    key: 'logout',
                    label: 'Abmelden',
                    icon: <LogoutOutlined/>,
                }
            ]}
            onSelect={logout}
        />
    </div>);
};