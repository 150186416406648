import {OrderPosition} from '../../../interfaces/OrderBundle';
import {Descriptions, Space} from 'antd';
import {RenderHelper} from '../../../util/renderHelper';
import React from 'react';

export const PositionsDetails = (props: { positions: OrderPosition[] }) => {

    return <Space direction={'vertical'} size={'middle'} style={{width: '100%'}}>
        {props.positions.map(position => <Descriptions column={1}>
            <Descriptions.Item label="Anzahl" span={1}>
                {position.amount}x
            </Descriptions.Item>

            <Descriptions.Item label="Name" span={2}>
                {position.item.name}
            </Descriptions.Item>

            <Descriptions.Item label="Artikelnummer" span={2}>
                {position.item.articleNumber}
            </Descriptions.Item>

            {position.item.totalSalePrice && position.item.totalSalePrice != position.item.price ?
                <Descriptions.Item label="Gesammt (Netto)" span={1}>
                    {RenderHelper.cents(position.item.totalSalePrice)}
                    <span style={{
                        textDecoration: 'line-through',
                        marginLeft: '2em'
                    }}> {RenderHelper.cents(position.item.price)}  </span>
                </Descriptions.Item>
                : <Descriptions.Item label="Gesammt (Netto)" span={1}>
                    {RenderHelper.cents(position.item.price)}
                </Descriptions.Item>}
        </Descriptions>)}
    </Space>;
};