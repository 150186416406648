import {Filter} from '../../../interfaces/Filter';
import {ENDPOINTS} from '../../../constants/ApiEndpoints';
import {UpdateModal} from '../../../components/input/UpdateModal';
import {Form, Input, Select} from 'antd';
import React from 'react';

export interface EditFilterProps {
    filter?: Filter,
    onClose?: () => void
}

export const EditFilter = (props: EditFilterProps) => {

    const [method, target]: ['PUT' | 'POST', string] = props.filter ?
        ['PUT', ENDPOINTS.adminUpdateFilter(props.filter)] :
        ['POST', ENDPOINTS.adminCreateFilter];

    return <UpdateModal item={props.filter || {}}
                        onClose={props.onClose}
                        title={'Filter'}
                        method={method}
                        targetUrl={target}>
        <Form.Item
            name="name"
            label="Name"
            rules={[
                {required: true},
            ]}
        >
            <Input/>
        </Form.Item>

        <Form.Item
            name="unit"
            label="Einheit"
            rules={[]}
        >
            <Input/>
        </Form.Item>

        <Form.Item name="filterType" label="Typ" rules={[
            {required: true},
        ]}>
            <Select
                options={[
                    {value: 'PROPERTY', label: 'Einzelwerte'},
                    {value: 'RANGE', label: 'Spanne'},
                ]}
            ></Select>
        </Form.Item>
    </UpdateModal>;
};