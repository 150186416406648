import {RenderHelper} from '../../../util/renderHelper';
import {Area, Column} from '@ant-design/plots';
import {Empty} from 'antd';
import React from 'react';

import {blue, cyan, green} from '@ant-design/colors';

export type CompanyStatField = 'soldNetVolume' | 'soldNumberOfItems' | 'soldNumberOfOrders';
export type  AutoChartProps = {
    data: any[],
    fieldName: CompanyStatField,
};


export const AutoChart = (props: AutoChartProps) => {
    const config = {
        data: props.data,
        yField: props.fieldName,
        xField: 'from',
        color: undefined as string | undefined,
        meta: {
            'soldNetVolume': {
                formatter: RenderHelper.cents
            },
            'soldNumberOfItems': {
                formatter: RenderHelper.number,
            },
            'soldNumberOfOrders': {
                formatter: RenderHelper.number,
            },
            'from': {
                formatter: RenderHelper.date,
            },
        }
    };

    switch (props.fieldName) {
        case 'soldNetVolume':
            config.color = blue.primary;
            break;
        case 'soldNumberOfItems':
            config.color = cyan.primary;
            break;
        case 'soldNumberOfOrders':
            config.color = green.primary;
            break;
        default:
        //
    }


    return <>
        {props.data.length > 0 && props.data.length <= 7 && <Column {...config}/>}
        {props.data.length > 7 && <Area {...config} smooth={true}/>}
        {props.data.length == 0 && <Empty/>}
    </>;

};