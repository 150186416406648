import axios from 'axios';

export interface FetchPagedArgs {
    /// The url to fetch (GET)
    url: string,
    /// Optional Query parameters
    query?: any,
    /// The key of the JSON Response where the data is returned.
    /// Guesses if omitted
    key?: string,
    /// Pagesize to use
    pageSize?: number,
}

export interface FetchState<T> {
    fetched: T[];
    token: string;
    finished: boolean;
    totalAmount: number | undefined
}

const DefaultPageSize = 100;
const TimeoutMs = 60_000;

export async function fetchPage<T>(args: FetchPagedArgs, initialState?: FetchState<T>): Promise<FetchState<T>> {

    const state = initialState || {
        fetched: [],
        token: '',
        finished: false,
        totalAmount: undefined
    };

    const params = new URLSearchParams(args.query || {});
    params.append('pageSize', '' + (args.pageSize || DefaultPageSize));
    params.append('token', state.token);

    const res = await axios.get(args.url, {params});

    let token = res.data['nextPageToken'];

    let finished = false;
    if (!token) {
        // The data was not page, we are finished?
        console.error(`No token found in response of ${args.url}. Assuming all data is loaded.`);
        console.log(res.data, token);
        finished = true;
        token = '';
    }
    if (token == '@end') {
        finished = true;
    }

    const totalAmount = res.data['totalAmount'] || undefined;

    // default to the first non-special key in response.
    const dataKey = args.key || Object.keys(res.data).find((e: string) => !(['nextPageToken', 'pageSize', 'totalAmount'].includes(e))) || 'data';

    const data = (res.data[dataKey] as T[]) || [];
    if (data == undefined) {
        console.log(`Failed loading paged data from ${args.url}: Key ${dataKey} not found.`);
    }
    if (!data) {
        finished = true;
    }

    return {
        fetched: state.fetched.concat(data),
        token,
        finished,
        totalAmount
    };
}

export async function fetchPageUntil<T>(args: FetchPagedArgs, until?: number): Promise<FetchState<T>> {
    let state: FetchState<T> | undefined = undefined;
    const timeoutAt = performance.now() + TimeoutMs;

    do {
        state = await fetchPage(args, state);

        if (until && until <= state.fetched.length) {
            break;
        }
        if (performance.now() > timeoutAt) {
            throw new Error(`Fetching multiple Pages of data from ${args.url} timed out after ${TimeoutMs}ms.`);
        }
    } while (!state.finished);

    return state;
}

export async function fetchAll<T>(args: FetchPagedArgs) {
    return fetchPageUntil<T>(args);
}

