import {Button, Form, Input, Modal, Select, Space} from 'antd';
import React from 'react';
import axios from 'axios';
import {ENDPOINTS} from '../../../constants/ApiEndpoints';
import {PermissionGroup} from '../../../interfaces/Permission';
import {fetchAll} from '../../../util/fetchPaged';
import {useAuth} from '../../../auth/AuthProvider';
import {useErrorHandling} from '../../../components/error/ErrorHandlerProvider';

export const AddUserModal = (
    {
        onAbort,
        onFinish,
    }: {
        onAbort: () => void,
        onFinish: () => void,
    }
) => {
    const [permissionGroups, setPermissionGroups] = React.useState<PermissionGroup[]>([]);
    const auth = useAuth();
    const handleErrors = useErrorHandling();

    React.useEffect(() => {
        fetchAll<PermissionGroup>({
            url: ENDPOINTS.permissionGroups,
        }).then((state) => {
            setPermissionGroups(state.fetched);
        }).catch(handleErrors);
    }, []);

    const onSubmit = (values: { [key: string]: string }) => {
        values = {
            companyId: auth.user?.companyId || '',
            supervisorId: auth?.user?.id || '',
            ...values
        };
        console.log('Inviting User', values);
        axios.post(ENDPOINTS.inviteUser, values)
            .then(onFinish).catch(handleErrors);
    };

    const validateMessages = {
        required: '${label} wird benötigt!',
        types: {
            email: '${label} ist keine valide Email-Addresse.',
        },
    };

    return (<Modal
        open={true}
        onCancel={() => onAbort()}
        footer={null}
    >
        <h3>Benutzer einladen</h3>
        <Form
            layout="vertical"
            onFinish={onSubmit}
            validateMessages={validateMessages}
            initialValues={{}}
        >

            <Form.Item label="E-Mail" name="mail" rules={[{type: 'email', required: true}]}>
                <Input placeholder="E-Mail"/>
            </Form.Item>

            <Form.Item label="Name">
                <Form.Item
                    label="Vorname"
                    name="firstName"
                    noStyle
                    rules={[{required: true}]}
                >
                    <Input style={{width: '50%'}} placeholder="Vorname"/>
                </Form.Item>
                <Form.Item
                    label="Nachname"
                    name="lastName"
                    noStyle
                    rules={[{required: true}]}
                >
                    <Input style={{width: '50%'}} placeholder="Nachname"/>
                </Form.Item>
            </Form.Item>

            <Form.Item label="Abteilung" name="department" rules={[{required: true}]}>
                <Input placeholder="Abteilung"/>
            </Form.Item>

            <Form.Item name="permissionGroupId" label="Berechtigungsgruppe" rules={[{required: true}]}>
                <Select
                    placeholder="Berechtigungsgruppe"
                    options={permissionGroups.map((p) => ({
                        label: p.name,
                        value: p.id
                    }))}
                ></Select>
            </Form.Item>

            <Space style={{width: '100%', justifyContent: 'flex-end'}}>
                <Button onClick={onAbort}>
                    Abbrechen
                </Button>

                <Button type="primary" htmlType="submit">
                    Fertig
                </Button>
            </Space>
        </Form>
    </Modal>);
};