import React from 'react';
import {Alert, Button, Form, Input, Space} from 'antd';

export const PasswordForm = (
    props: {
        onFinish: (password: string, setError: (e: string) => void) => void,
    }
) => {
    const [passwordVisible, setPasswordVisible] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');

    const onFinish = ({
                          password,
                          passwordRepeat
                      }: {
        password: string,
        passwordRepeat: string
    }) => {
        setErrorMessage('');

        if (password != passwordRepeat) {
            setErrorMessage('Die Passwörter stimmen nicht überein.');
        }

        props.onFinish(
            password,
            setErrorMessage,
        );
    };

    const validateMessages = {
        required: '${label} wird benötigt!',
    };

    return <>
        {errorMessage ? <Alert
            message={errorMessage}
            type={'error'}
        /> : <></>}

        <p>Bitte wählen Sie ein Passwort.</p>

        <Form
            layout="vertical"
            initialValues={{}}
            validateMessages={validateMessages}
            onFinish={onFinish}
        >
            <Form.Item label="Passwort" name="password"
                       rules={[
                           {required: true},
                           {
                               pattern: /\S{8,}/,
                               message: 'Das Passwort muss aus mindestens 8 Zeichen bestehen.'
                           }
                       ]}
            >
                <Input.Password
                    visibilityToggle={{visible: passwordVisible, onVisibleChange: setPasswordVisible}}
                />
            </Form.Item>

            <Form.Item label="Passwort wiederholen" name="password-repeat" rules={[{required: true},]}>
                <Input.Password
                    visibilityToggle={{visible: passwordVisible, onVisibleChange: setPasswordVisible}}

                />
            </Form.Item>

            <Space style={{width: '100%', justifyContent: 'flex-end'}}>
                <Button type="primary" htmlType="submit">
                    Abschließen
                </Button>
            </Space>
        </Form>
    </>;


};