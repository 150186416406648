import React from 'react';
import {App, Button} from 'antd';
import {ENDPOINTS} from '../../constants/ApiEndpoints';

export interface ImageUploadProps {
    value?: string | null;
    name?: string,
    onChange?: (value: string | null) => void;
    initialImageId?: string,
}

export const ImageUpload = (props: ImageUploadProps) => {
    const {notification} = App.useApp();

    const displayError = (msg: string, e?: any) => {
        console.log(e);
        notification.error({
            message: msg,
        });
    };

    const inputFile = React.useRef<HTMLInputElement | null>(null);
    const [imageFile, setImageFile] = React.useState<string | null>(null);

    const onChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target?.files?.item(0);
        if (!file) {
            return;
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
            console.log(reader.result);
            setImageFile(reader.result as string);
            if (props.onChange) {
                props.onChange((reader.result as string).split(',')[1]);
            }
        };
        reader.onerror = (error) => {
            displayError('Fehler beim Lesen der Datei.', error);
        };
    };

    return <div style={{display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center'}}>
        <img
            style={{
                width: 200,
                maxHeight: 200,
                border: '2px dashed',
                padding: 12,
                borderRadius: 6,
            }}
            src={imageFile || ENDPOINTS.image({imageId: props.initialImageId || ''})}
            alt={props.name || 'Logo'}
        />
        <Button onClick={() => inputFile.current?.click()}>{props.name || 'Logo'} ändern</Button>
        <input type='file'
               id='file'
               ref={inputFile}
               onChange={onChangeFile}
               style={{display: 'none'}}
               accept="image/png, image/jpeg"
        />
    </div>;
};