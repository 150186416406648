import React, {RefObject} from 'react';

interface ScrollDetectorProps {
    children: React.ReactNode
    onScrollDown: () => void;
}

export class ScrollDetector extends React.Component<ScrollDetectorProps> {

    contentNode: RefObject<HTMLDivElement>;

    constructor(props: ScrollDetectorProps) {
        super(props);
        this.contentNode = React.createRef();
    }

    onScroll = () => {
        const element = this.contentNode.current;
        if (!element) {
            console.error('[ScrollDetector] Wrapper Element not found.');
            return;
        }

        const isAtBottom = Math.abs(element.scrollHeight - element.clientHeight - element.scrollTop) < 50;
        if (isAtBottom) {
            this.props.onScrollDown();
        }
    };

    componentDidMount() {
        window.addEventListener('scroll', this.onScroll, false);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll, false);
    }

    render() {
        return <div ref={this.contentNode}>{this.props.children}</div>;
    }
}

