import React from 'react';
import {CaretRightOutlined} from '@ant-design/icons';

import './Collapse.css';

const MAX_HEIGHT = 1000000;

export const Collapse = (props: {
    items: (string | React.ReactNode)[],
    length?: number,
}) => {
    const length = props.length || 1;
    const desc = props.items.slice(0, length);
    const hidden = props.items.slice(length, props.length);

    const maxHeightRef = React.useRef(MAX_HEIGHT);
    const collapseDivRef = React.useRef<HTMLDivElement | null>(null);

    const [isHidden, setHidden] = React.useState(true);

    React.useEffect(() => {
        if (!collapseDivRef.current || !collapseDivRef.current?.style) {
            return;
        }

        if (maxHeightRef.current === MAX_HEIGHT) {
            maxHeightRef.current = collapseDivRef.current?.offsetHeight;
        }

        if (isHidden) {
            collapseDivRef.current.style.maxHeight = '0px';
        } else if (!isHidden) {
            collapseDivRef.current.style.maxHeight = maxHeightRef.current + 'px';
        }
    }, [isHidden]);

    return <div className={isHidden ? 'hidden collapse' : 'collapse'} onClick={() => setHidden(!isHidden)}>
        <div className="collapse-icon-holder">
            {hidden.length ? <CaretRightOutlined className="collapse-icon"/> : <></>}
        </div>
        <div>
            <div>
                {desc.map((t, idx) => (<>
                    {idx == 0 ? <></> : <br/>}
                    {t}
                </>))}
                {isHidden && hidden.length > 0 ? <b> ...</b> : <></>}
            </div>
            <div className="collapse-content" ref={collapseDivRef}>
                {hidden.map((t, idx) => (<>
                    {idx == 0 ? <></> : <br/>}
                    {t}
                </>))}
            </div>
        </div>
        <div className="content"></div>

    </div>;
};
