import React, {MutableRefObject} from 'react';
import {Button, Space, Spin} from 'antd';
import {ReloadOutlined} from '@ant-design/icons';
import {ScrollDetector} from './ScrollDetector';
import {fetchPage, FetchPagedArgs, fetchPageUntil, FetchState} from '../../util/fetchPaged';


export interface AutoFetchDataProps<T> extends FetchPagedArgs {
    /// Sets callback to invalidate the content.
    /// All content is then reloaded.
    invalidateCallback?: MutableRefObject<(() => void) | undefined>
    /// Callback when data gets loaded. Data is combined data from all pages.
    onLoadData: (event: {
        data: T[],
        totalAmount: number | undefined
    }) => void,
    onErrorLoading?: ((e: any) => void)
    /// Should there be a button to trigger fetches manually? (Default: false)
    /// If not data is fetched when the bottom of the page is scrolled to.
    manuelFetch?: boolean
    children: React.ReactNode,
}


export const AutoFetchData = <T, >(props: AutoFetchDataProps<T>) => {
    const {url, key, onLoadData} = props;

    const [isLoading, setIsLoading] = React.useState(false);
    const [hasLoaded, setHasLoaded] = React.useState(false);

    const [loadingState, setLoadingState] = React.useState<FetchState<T>>({
        fetched: [],
        token: '',
        finished: false,
        totalAmount: undefined
    });

    React.useEffect(() => {
        onLoadData({
            data: loadingState.fetched,
            totalAmount: loadingState.totalAmount
        });
    }, [loadingState]);

    // Use json for react effect, as object will only compare shallowly
    const queryJson = JSON.stringify(props.query);
    React.useEffect(() => {
        reFetchAll();
    }, [queryJson, props.url]);

    const reFetchAll = () => {
        (async () => {
            try {
                const state = await fetchPageUntil<T>(props, loadingState.fetched.length);
                setLoadingState(state);
            } catch (e) {
                console.error(e);
                if (props.onErrorLoading) props.onErrorLoading(e);
            }
            setHasLoaded(true);
        })();
    };

    if (props.invalidateCallback) {
        props.invalidateCallback.current = reFetchAll;
    }

    const fetchNextPage = () => {
        if (loadingState.finished) {
            // There is more data left to load
            return;
        }
        if (isLoading) {
            // currently fetching data, do not retrigger.
            return;
        }

        setIsLoading(true);
        (async () => {
            try {
                const state = await fetchPage(props, loadingState);
                setLoadingState(state);
            } catch (e) {
                console.error(e);
                if (props.onErrorLoading) props.onErrorLoading(e);
            }
            setIsLoading(false);
            setHasLoaded(true);
        })();
    };

    if (props.manuelFetch) {
        return <div>
            {hasLoaded ? props.children : <> </>}
            {isLoading ? <Spin/> : <></>}
            {(!loadingState.finished) ?
                <Space style={{width: '100%', justifyContent: 'center', marginTop: 16}}>
                    <Button onClick={fetchNextPage} icon={<ReloadOutlined/>}>Mehr Laden</Button>
                </Space> : <></>}
        </div>;
    } else {
        return <ScrollDetector onScrollDown={() => fetchNextPage()}>
            {hasLoaded ? props.children : <> </>}
            {isLoading ? <Spin/> : <></>}
            {(!loadingState.finished) ?
                <Space style={{width: '100%', justifyContent: 'center', marginTop: 16}}>
                    <Button onClick={fetchNextPage} icon={<ReloadOutlined/>}>Mehr Laden</Button>
                </Space> : <></>}
        </ScrollDetector>;
    }


};