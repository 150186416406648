import React from 'react';
import {Space} from 'antd';
import {CenterModal} from '../../components/layout/CenterModal';
import {useNavigate} from 'react-router-dom';

export const PasswordResetDone = () => {
    const navigate = useNavigate();

    React.useEffect(() => {
        window.setTimeout(() => {
            navigate('/');
        }, 5000);
    }, []);

    return <CenterModal showLogo={true}>
        <Space direction="vertical"
               style={{width: '100%', padding: 16, boxSizing: 'border-box', alignItems: 'center'}}>

            <h3>
                Passwort wurde zurückgesetzt. Sie werden zum Login weitergeleitet.
            </h3>
        </Space>
    </CenterModal>;
};