import React from 'react';
import {AddUserModal} from './AddUserModal';
import {App, Button} from 'antd';
import {PlusOutlined} from '@ant-design/icons';

interface InviteButtonProps {
    invalidateCallback: () => void,
}

export const InviteButton = (props: InviteButtonProps) => {
    const [inviteUser, setInviteUser] = React.useState<boolean>(false);
    const {notification} = App.useApp();

    return <div>
        <Button icon={<PlusOutlined/>} type="primary"
                onClick={() => setInviteUser(true)}
        >Admin Einladen</Button>
        {
            inviteUser ? (
                <AddUserModal
                    onAbort={() => {
                        setInviteUser(false);
                        props.invalidateCallback();
                    }}
                    onFinish={() => {
                        notification.info({
                            message: 'E-Mail wurde versendet.'
                        });
                        setInviteUser(false);
                        props.invalidateCallback();
                    }}/>
            ) : <></>
        }
    </div>;
};