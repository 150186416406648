import {CenterModal} from '../../components/layout/CenterModal';
import {Space} from 'antd';
import React from 'react';
import axios from 'axios';
import {User} from '../../interfaces/User';
import {UserPermissions} from '../../interfaces/Permission';
import {PasswordForm} from './component/PasswordForm';
import {ENDPOINTS} from '../../constants/ApiEndpoints';
import {useNavigate, useParams} from 'react-router-dom';
import {useAuth} from '../../auth/AuthProvider';

export const MailInviteSetPassword = () => {
    const {linkHash} = useParams<string>();
    const navigate = useNavigate();
    const auth = useAuth();

    const onFinish = (password: string, setErrorMessage: (e: string) => void) => {
        (async () => {
            try {
                const res = await axios.post(ENDPOINTS.finishAppRegistration, {
                    deeplinkHash: linkHash,
                    password: password
                });

                const user: User = res.data['user'];
                if (!user.permissionGroup?.permissions?.includes(UserPermissions.ADMIN_USE)) {
                    navigate('/');
                    return;
                }

                const token: string = res.data['token'];
                auth.loginByToken(token);

                navigate('/dashboard');
            } catch (e) {
                console.log(e);
                setErrorMessage('Fehlgeschlagen.');
            }
        })();
    };

    return <CenterModal showLogo={true}>
        <Space direction="vertical" style={{width: '100%', padding: 16, boxSizing: 'border-box'}}>
            <h1 style={{marginBottom: 8}}>Admin Panel</h1>
            <h2 style={{marginTop: 0}}>Registrierung abschließen</h2>

            <PasswordForm onFinish={onFinish}/>
        </Space>
    </CenterModal>;
};