import {useAuth} from './AuthProvider';
import {Navigate, Outlet} from 'react-router-dom';
import React from 'react';
import {Spin} from 'antd';

export const ProtectedRoutes = () => {
    const auth = useAuth();

    if (!auth.loggedIn) {
        console.log('Not logged in redirect to /');
        return <Navigate to="/" replace/>;
    }

    if (!auth.user) {
        return <Spin/>;
    }

    return <Outlet/>;
};