import {App} from 'antd';
import React, {createContext, useContext} from 'react';
import {AxiosError} from 'axios';


type ErrorMessagesKind = {
    [key: string]: string,
    Other: string
}

type ErrorMessages = {
    ErrNetwork: string
    action: ErrorMessagesKind,
    loadingData: ErrorMessagesKind,
}

const messages: ErrorMessages = {
    ErrNetwork: 'Es konnte keine Verbindung zum Server hergestellt werden. Bitte überprüfen Sie ihre Internetverbindung oder versuchen Sie es später erneut.',
    action: {
        ErrInternal: 'Die Aktion konnte nicht ausgeführt werden wegen eines internen Serverfehlers.',
        ErrUnauthorized: 'Sie haben keine Berechtigung diese Aktion auszuführen.',
        Other: 'Fehler beim Ausführen der Aktion.',
    },
    loadingData: {
        ErrInternal: 'Daten konnten nicht geladen werden wegen eines internen Serverfehlers.',
        ErrUnauthorized: 'Daten konnten nicht geladen werden. Sie haben nicht die notwendigen Berechtigungen.',
        Other: 'Fehler beim Laden der Daten.',
    }
};

export type ErrorHandlerContextType = (e: Error) => void;
const ErrorHandlerContext = createContext<ErrorHandlerContextType>((e) => {
    throw e;
});

interface Props {
    children: React.ReactNode
}

export const useErrorHandling = () => {
    return useContext(ErrorHandlerContext);
};

export const ErrorHandlerProvider = (props: Props) => {
    const {notification} = App.useApp();
    const axiosHandler = (error: AxiosError) => {
        // Docs @ https://axios-http.com/docs/handling_errors
        if (error.response?.status) {
            // Response, but error
            console.error(error);

            const errorsMessages = error.request?.method == 'GET' ?
                messages.loadingData : messages.action;

            try {
                const json = error.response.data as string;
                const data = JSON.parse(json);

                const msg = errorsMessages[data.error] || errorsMessages.Other;
                notification.error({
                    message: messages.ErrNetwork,
                });
            } catch (e) {
                console.log(e);
                notification.error({
                    message: messages.ErrNetwork,
                });
            }
        } else if (error.request) {
            notification.error({
                message: messages.ErrNetwork,
            });
        }

        return Promise.reject(error);
    };

    const handler = (e: Error) => {
        if (e instanceof AxiosError) {
            axiosHandler(e);
        } else {
            throw e;
        }
    };

    return (
        <ErrorHandlerContext.Provider value={handler}>{props.children}</ErrorHandlerContext.Provider>
    );
};