import {Progress, Space, Tag} from 'antd';
import {AutoFetchTable} from '../../components/table/AutoFetchTable';
import {ENDPOINTS} from '../../constants/ApiEndpoints';
import Column from 'antd/es/table/Column';
import React, {useRef} from 'react';
import {DetailOrderBundle} from './components/DetailOrderBundle';
import {DeliveryStatus, Order, OrderBundle, OrderBundleStatus} from '../../interfaces/OrderBundle';
import {RenderHelper} from '../../util/renderHelper';
import {Collapse} from '../../components/misc/Collapse';
import {EyeOutlined} from '@ant-design/icons';
import {OrderBundleStatusNames} from '../../util/enumNames';
import {SelectApiSearchHelper} from '../../util/SelectApiSearchHelper';

export const ManageOrderBundles = () => {
    const invalidateCallback = useRef<() => void>();

    function renderItemList(o: OrderBundle) {
        return <Collapse items={o.orders.flatMap(o => o.positions).map(o => `${o.amount}x ${o.item.name}`)}/>;
    }

    function renderSellers(o: OrderBundle) {
        return <Collapse items={o.orders.flatMap(o => o.sellerCompany?.name || '')}/>;
    }

    return <Space direction="vertical" size="large" style={{width: '100%'}}>
        <h1>Bestellungen</h1>
        
        <AutoFetchTable
            actionIcon={EyeOutlined}
            invalidateCallback={invalidateCallback}
            url={ENDPOINTS.adminListOrderBundles}
            modal={(item, onClose) => <DetailOrderBundle onClose={onClose} order={item}/>}
            transformFunction={(order: OrderBundle) => (order)}
            query={{}}
            searchItems={[
                {value: 'companyId', label: 'Käufer', selectFetch: SelectApiSearchHelper.findCompany},
                {value: 'sellerCompanyId', label: 'Verkäufer', selectFetch: SelectApiSearchHelper.findCompany},
                {
                    value: 'status', label: 'Status', select: Object.entries(OrderBundleStatusNames)
                        .map(([value, label]) => {
                            return {value, label};
                        })
                }
            ]}>
            <Column title="Nummer" dataIndex="bundleNumber" key="bundleNumber" sorter={true}/>
            <Column title="Datum" dataIndex="date" key="date" sorter={true} render={RenderHelper.dateTime}/>
            <Column title="Käufer" dataIndex="" key="company.name"
                    render={(o: OrderBundle) => `${o.user.firstName} ${o.user.lastName}`}/>
            <Column title="Käuferunternehmen" dataIndex={['company', 'name']} key="company.name" sorter={true}/>
            <Column title="Verkäuferunternehmen" dataIndex="" key="company.name" render={renderSellers}/>
            <Column title="Nettopreis (Bruttopreis)" dataIndex="priceNet" key="priceNet" sorter={true}
                    render={(_: any, order: Order) => {
                        return `${RenderHelper.cents(order.priceNet)} (${RenderHelper.cents(order.priceGross)})`;
                    }}/>
            <Column title="Status" dataIndex="status" key="status" sorter={true}
                    render={(status: OrderBundleStatus) => {
                        const text = OrderBundleStatusNames[status] || status;
                        if (status == OrderBundleStatus.IN_PROCESS) {
                            return <Tag color={'blue'}>{text}</Tag>;
                        } else if (status == OrderBundleStatus.FINISHED) {
                            return <Tag color={'green'}>{text}</Tag>;
                        } else {
                            return <Tag>{text}</Tag>;
                        }
                    }}/>
            <Column title="Versandstatus" dataIndex="" key="shippingState" render={(_: any, order: OrderBundle) => {
                const totalOrders = order.orders.length;
                const sentOrders = order.orders.filter(o => o.delivery.status == DeliveryStatus.SENT).length;
                const deliveredOrders = order.orders.filter(o => o.delivery.status == DeliveryStatus.DELIVERED).length;

                return <Progress percent={(sentOrders * 0.5 + deliveredOrders) / totalOrders}/>;
            }}/>
            <Column title="Letzte Änderung" dataIndex="" key="lastChange" render={(_: any, order: OrderBundle) => {
                return RenderHelper.dateTime(order.examinationHistory[order.examinationHistory.length - 1]?.date);
            }}/>
            <Column title="Details" dataIndex="" width="20%" key="details"
                    render={renderItemList}/>

        </AutoFetchTable>
    </Space>;
};