import {Input, Select, Space} from 'antd';
import React from 'react';
import './searchbar.css';
import {SelectApiSearch} from './SelectApiSearch';

export interface SearchbarProps {
    items: {
        value: string,
        label: string,
        select?: { value: string, label: string }[],
        selectFetch?: (search: string) => Promise<{ value: string, label: string }[]>,
    }[],
    onChange: (map: { [v: string]: string }) => void,
    delayMs?: number,
}


export const Searchbar = (props: SearchbarProps) => {
    const [searchText, setSearchText] = React.useState<string>('');
    const [selectedValue, setSelectedValue] = React.useState<string>(props.items[0]?.value || '');

    const delayMs = props.delayMs === undefined ? 250 : props.delayMs;
    const selected = props.items.find((i) => i.value == selectedValue);

    React.useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (!searchText) {
                return props.onChange({});
            }

            const map: { [v: string]: string } = {};
            map[selectedValue] = searchText;
            props.onChange(map);
        }, selected?.select ? 0 : delayMs);
        return () => clearTimeout(delayDebounceFn);
    }, [searchText, selectedValue]);

    const selectBefore = (
        <Select
            className={'searchbar-selector'}
            options={props.items}
            onChange={(value) => {
                setSelectedValue(value);
                setSearchText('');
            }}
            style={{minWidth: '10em'}}
            defaultValue={selectedValue}>
        </Select>
    );


    if (selected?.selectFetch) {
        return <Space.Compact style={{width: '100%'}}>
            {selectBefore}
            <SelectApiSearch
                key={selected.label}
                selectFetch={selected.selectFetch}
                onChange={setSearchText}
            />
        </Space.Compact>;
    } else if (selected?.select) {
        const options = [{
            value: '',
            label: '-'
        }].concat(selected.select);

        return <Space.Compact style={{width: '100%'}}>
            {selectBefore}
            <Select
                options={options}
                onChange={setSearchText}
                style={{flexGrow: 1}}
                defaultValue={''}
            />
        </Space.Compact>;
    } else {
        return <Space.Compact style={{width: '100%'}}>
            {selectBefore}
            <Input
                style={{flexGrow: 1}}
                value={searchText}
                placeholder="Filtern"
                onChange={(e) => setSearchText(e.target.value)}/>
        </Space.Compact>;
    }
};

